
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";

export default defineComponent({
  props: {
    dimension: String, // ex. 15px or 50mm
    label: String,
  },
  components: {},
  setup(props) {
    watch(
      () => props.dimension,
      (value) => {
        // do something
        splitDimension(value!);
      }
    );
    let dimension = "";
    let unit = "mm";
    const label = computed(() => props.label || "");

    const splitDimension = (value: string) => {
      let values = value.match(/[a-z]+|[^a-z]+/gi);
      dimension = values![0];
      unit = values![1] || "mm";
    };

    // onMounted(() => {
    //   syncSelectionToCss();
    // });

    // emit("update:dimension", dimension);

    return {
      dimension,
      label,
    };
  },
});
