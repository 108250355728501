
import { defineComponent, ref, reactive, computed, watch } from "vue";
import { menuItems } from "./menu-items";
import { useStore } from "../../store/store";
import { MutationTypes } from "../../store/mutation-types";
import { ActionTypes } from "../../store/action-types";
import { Spread, Layer, PreviewArgument, Package } from "@bubblydoo/common";

export default defineComponent({
  props: ["title", "spread"],
  components: {},
  setup(props, { emit }) {
    const store = useStore();

    const activeItem = ref(null);

    return {
      menuItems,
      activeItem,
      toggleSelect(item: any) {
        activeItem.value = item;
        // emit("update:activeMenuItem", activeItem);
      },
      isActive(checkItem: any) {
        return activeItem.value == checkItem;
      },
    };
  },
});
