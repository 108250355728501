
/// <reference types="resize-observer-browser"/>
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import MonacoEditor from "@bubblydoo/vue-monaco";
import { getMonacoLoader } from "@/monaco-support";
import type { editor as monacoEditor } from "monaco-editor";
import type * as monacoNamespace from "monaco-editor";
export type monacoSettings = typeof monacoNamespace;

const MIN_HEIGHT = 100;
const LINE_HEIGHT = 18;
const SCROLLBAR_SIZE = 10;

export default defineComponent({
  props: {
    modelValue: String,
    language: String,
    adjustToContent: Boolean,
    wrap: Boolean,
  },
  components: {
    MonacoEditor,
  },
  setup(props, { emit }) {
    const code = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (value) => {
        code.value = value;
      }
    );

    const language = computed(() => props.language || "css");
    const adjustToContent = computed(() => !!props.adjustToContent);
    // wrap and adjustToContent at the same time leads to crash
    // because resizeEditor is called all the time
    // because the height is not stable
    const wrap = computed(() => !!props.wrap && !props.adjustToContent);

    let editor: monacoEditor.IStandaloneCodeEditor;
    let monaco: monacoSettings;

    const initializeEditor = () => {
      editor.updateOptions({
        scrollBeyondLastLine: false,
        minimap: {
          enabled: false,
        },
        scrollbar: {
          alwaysConsumeMouseWheel: !adjustToContent.value,
          handleMouseWheel: !adjustToContent.value,
          useShadows: false,
          vertical: adjustToContent.value ? "hidden" : "auto",
          verticalScrollbarSize: SCROLLBAR_SIZE,
          horizontalScrollbarSize: SCROLLBAR_SIZE,
        },
        quickSuggestions: {
          other: false,
          comments: false,
          strings: false,
        },
        parameterHints: {
          enabled: false,
        },
        suggestOnTriggerCharacters: false,
        acceptSuggestionOnEnter: "off",
        tabCompletion: "off",
        wordBasedSuggestions: false,
        lightbulb: {
          enabled: false,
        },
        contextmenu: false,
        hover: {
          enabled: false,
        },
        // wordWrap: wrap.value ? "on" : undefined
      });
      editor.getModel()!.updateOptions({
        tabSize: 2,
      });
      editor.onDidChangeModelDecorations(() => {
        if (adjustToContent.value) {
          updateEditorHeight(); // typing
          requestAnimationFrame(() => {
            updateEditorHeight();
            editor.layout();
          }); // folding
        }
      });
      emit("initialize", { editor, monaco });
    };

    const resizeEditor = () => {
      if (editor) {
        updateEditorHeight();
        editor.layout();
      }
    };

    const wrapperEl = ref<HTMLDivElement | null>(null);

    const resizeObserver = new ResizeObserver((entries) => {
      resizeEditor();
    });

    onMounted(() => {
      resizeObserver.observe(wrapperEl.value!);
    });

    let prevHeight = 0;

    const updateEditorHeight = () => {
      // mostly borrowed from https://github.com/microsoft/monaco-editor/issues/794#issuecomment-583367666
      const editorElement = editor.getDomNode();

      if (!editorElement) {
        return;
      }

      // const lineHeight = editor.getOption(47 /* monacoEditor.EditorOption.lineHeight */);
      const lineHeight = LINE_HEIGHT;
      const model = editor.getModel();
      const lineCount = model ? model.getLineCount() : 1;
      let height = editor.getTopForLineNumber(lineCount + 1) + lineHeight;
      height = Math.max(MIN_HEIGHT, height + SCROLLBAR_SIZE);

      if (prevHeight !== height) {
        prevHeight = height;
        editorElement.style.height = `${height}px`;
      }
    };

    const setMonaco = () => {
      monaco = (window as any).monaco;
    };

    return {
      monacoLoader: () => getMonacoLoader(),
      code,
      language,
      change(ev: string | InputEvent) {
        if (typeof ev !== "string") {
          console.warn("Monaco Editor gave something else than a string", ev);
          return;
        }
        // console.log("[MonacoWrapper] inside change", ev);
        emit("update:modelValue", ev);
      },
      wrapperEl,
      mountedEditor(_editor: monacoEditor.IStandaloneCodeEditor) {
        setMonaco();
        editor = _editor;
        initializeEditor();
      },
    };
  },
});
