
import { defineComponent, computed, ref, watch } from "vue";
import { Package, Project, PackageData, PreviewArgument } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import PanelSection from "../panel-general/PanelSection.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import JsonEditor from "../../inputs/JsonEditor.vue";

import { PROCESSOR_WS_URL, TRANSFO_API_URL, STORE_BASE_URL } from "@/config";
import cloneDeep from "lodash/cloneDeep";
import ExportRenderContainer from "@/components/display/ExportRenderContainer.vue";

export default defineComponent({
  props: [
    "pkg",
    "viewKey"
  ],
  components: {
    Panel,
    PanelAttribute,
    ExportRenderContainer
  },
  setup(props, { emit }) {
    console.log(props)
    const viewKey = computed(() => props.viewKey);
    const pkg = computed(() => props.pkg as Package);
    const previewArguments = ref<PreviewArgument[]>(props.pkg.data.previewArguments);
    const selectedArguments = ref<PreviewArgument[]>([])
    const messages = ref<any[]>([]);
    const croppings = ref<string[]>([]);
    const exporting = ref(false);
    const exportStuff = ref({
      target: "pdf",
      maximumQuality: "300",
      fileFormat: "png",
      transparentBackground: false,
      processorWebsocketUrl: PROCESSOR_WS_URL,
      transfoStoreUrl: TRANSFO_API_URL
    });

    return {
      pkg,
      previewArguments,
      exportStuff,
      messages,
      croppings,
      viewKey,
      STORE_BASE_URL,
      selectedArguments, exporting,
      renderView: [viewKey.value],
      removeArgs(args: PreviewArgument) {
        selectedArguments.value.splice(selectedArguments.value.indexOf(args), 1);
        console.log("removeArgs", args)
      },
      addArgs(args: PreviewArgument) {
        console.log("addArgs", args)
        selectedArguments.value.push(args);
      },
      async runExport() {
        console.log("runExport", selectedArguments.value)
        exporting.value = true;
        // const socket = new WebSocket(exportStuff.value.processorWebsocketUrl);
        // messages.value = [];


        // socket.onopen = () => {
        //   console.log("Websocket connected");
        //   socket.send(
        //     JSON.stringify({
        //       action: "process",
        //       request: JSON.stringify({
        //         packageId: pkg.value.id,
        //         runArguments: {},
        //         transfoStoreUrl: exportStuff.value.transfoStoreUrl,
        //         projectsStoreUrl: STORE_BASE_URL,
        //         instruction: {
        //           captures: [
        //             {
        //               view: viewKey.value,
        //               spreads: "*",
        //               croppings: croppings.value.length ? croppings.value : undefined,
        //             },
        //           ],
        //           target: {
        //             type: exportStuff.value.target,
        //             options: {
        //               fileFormat: exportStuff.value.fileFormat,
        //             },
        //             consolidation: {
        //               level: exportStuff.value.target === "pdf" ? "view" : undefined,
        //             },
        //           },
        //           notifications: {
        //             websocket: {
        //               enabled: true,
        //               throwOnGone: true,
        //             },
        //           },
        //           derivativeMaximumQuality: exportStuff.value.maximumQuality,
        //           parallelization: "all",
        //         },
        //       }),
        //     })
        //   );
        // };

        // socket.onmessage = (event) => {
        //   console.log(event);
        //   const data = JSON.parse(event.data);
        //   console.log(data);
        //   messages.value.push(data);
        // };
      },
    };
  },
});
