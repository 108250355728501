
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const visible = computed(() => props.visible);
    const visibleOverride = ref<boolean | null>(null);
    const title = computed(() => props.title);

    const visibleResult = computed(() =>
      visibleOverride.value !== null ? visibleOverride.value : visible.value
    );



    return {
      title,
      visible: visibleResult,
      toggle() {
        visibleOverride.value = !visibleResult.value;
      },
    };
  },
});
