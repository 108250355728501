
import { defineComponent, computed, ref, onUnmounted, watch } from "vue";

export default defineComponent({
  props: {
    title: String,
    expandable: Boolean,
  },
  setup(props) {
    const title = computed(() => props.title);
    const expanded = ref(false);

    const handleKey = (ev: KeyboardEvent) => {
      // close on escape
      if (ev.keyCode === 27) expanded.value = false;
    };

    watch(expanded, (e) => {
      if (e) {
        window.addEventListener("keydown", handleKey);
      }
    });

    onUnmounted(() => window.removeEventListener("keydown", handleKey));



    return {
      title,
      expanded,
      expand() {
        expanded.value = true;
      },
    };
  },
});
