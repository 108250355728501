
import { defineComponent, ref, reactive, computed, watch } from "vue";
import { useStore } from "@/store/store";
import { Layer } from "@bubblydoo/common";
import { VueDraggableNext } from "vue-draggable-next";
import ConfirmingButton from "../../inputs/ConfirmingButton.vue";
import { isOperatorNode } from "mathjs";

export default defineComponent({
  props: ["layers", "depth", "selectedLayers"],
  components: {
    draggable: VueDraggableNext, ConfirmingButton: ConfirmingButton
  },
  name: "LayersPanelLayer",
  setup(props, { emit }) {
    const selectedLayers = ref(props.selectedLayers);

    const showChildren = ref(props.layers.map(() => false))

    watch(
      computed(() => props.selectedLayers),
      (v) => {
        selectedLayers.value = v;
      }
    );

    const depth = computed(() => props.depth);



    return {
      layers: computed(() => props.layers),
      depth,
      showChildren,
      // spread: computed(() => props.spread),
      padderStyle: computed(() => ({
        paddingLeft: depth.value ? depth.value * 15 + "px" : 0,
        backgroundColor: '#00000088'
      })),
      // selected,
      selectedLayers,
      toggleSelect(layerWithClickEvent: { layer: Layer, event: MouseEvent }) {
        emit("toggle-select", layerWithClickEvent);
        console.log(selectedLayers.value);
      },
      isSelected(layer: Layer) {
        if (selectedLayers.value) return selectedLayers.value.has(layer);
        return false;
      },
      async copy(layer: Layer) {
        navigator.clipboard.writeText(JSON.stringify(layer))
      },
      log(event: any) {
        console.log(event);
        // console.log(layer.value);
      },
      setVisibility(layerIndex: number) {
        showChildren.value[layerIndex] = !showChildren.value[layerIndex];
        console.log(showChildren)
      },
      isOpen(layerIndex: number) {
        return showChildren.value[layerIndex]
      }
    };
  },
});
