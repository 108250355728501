
import { computed, defineComponent, ref, watch } from "vue";
import { TransfoStoreClient, TransfoStoreSearchCommand } from "@bubblydoo/resolver";
import { TransfoSvgLayer } from "@bubblydoo/common";
import { TRANSFO_API_URL } from "@/config";

const DEFAULT_JSON_STRING = "{}";

export default defineComponent({
  props: {
    layer: Object,
  },
  setup(props, { emit }) {
    const pkgResult = ref<string | false | null>(null);

    const resetDerivative = (layer: TransfoSvgLayer) => {
      if (layer && !layer.derivative) {
        layer.derivative = "main";
      }
    };

    const layer = computed(() => props.layer as TransfoSvgLayer);

    watch(layer, resetDerivative);

    resetDerivative(props.layer as TransfoSvgLayer);

    const client = new TransfoStoreClient(TRANSFO_API_URL);

    return {
      pkgResult,
      search: async (cmd: TransfoStoreSearchCommand) => {
        const r = await client.search(cmd);
        pkgResult.value = r ? `${r.version}` : false;
      },
      availableDerivatives: computed(() => {
        const arr = [
          "main",
          "png-010",
          "png-030",
          "png-050",
          "png-080",
          "png-100",
          "png-200",
          "png-300",
          "png-400",
          "avif-100",
          "avif-200",
          "avif-300",
          "avif-400",
          "avif-100-lossy-100",
          "avif-200-lossy-100",
          "avif-300-lossy-100",
          "avif-400-lossy-100",
          "avif-100-lossy-80",
          "avif-200-lossy-80",
          "avif-300-lossy-80",
          "avif-400-lossy-80",
        ];
        if (layer.value && layer.value.derivative && !arr.includes(layer.value.derivative)) {
          arr.unshift(layer.value.derivative);
        }
        return arr;
      }),
    };
  },
});
