const MONACO_CDN_BASE_URL = "https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.20.0/min";

import requirejsStr from "!!raw-loader!./vendor/require.js";

export function addMonacoSupport() {
  // Before loading vs/editor/editor.main, define a global MonacoEnvironment that overwrites
  // the default worker url location (used when creating WebWorkers). The problem here is that
  // HTML5 does not allow cross-domain web workers, so we need to proxy the instantiation of
  // a web worker through a same-domain script
  (window as any).MonacoEnvironment = {
    getWorkerUrl(workerId: string, label: string) {
      return `data:text/javascript;charset=utf-8,${encodeURIComponent(`
          self.MonacoEnvironment = {
            baseUrl: '${MONACO_CDN_BASE_URL}'
          };
          importScripts('${MONACO_CDN_BASE_URL}/vs/base/worker/workerMain.js');`)}`;
    },
  };
}

export function getMonacoLoader() {
  if (!("requirejs" in window)) {
    // dirty, but it is kind of the only way to load requirejs
    // as it's not commonjs or umd compatible at all (I think)
    (window, eval)(requirejsStr);
  }

  const loader = (window as any).requirejs;

  if (!loader) throw new Error("RequireJS not loaded");

  loader.config({ paths: { vs: `${MONACO_CDN_BASE_URL}/vs` } });

  return loader;
}
