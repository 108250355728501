
import { defineComponent, computed, ref, watch } from "vue";
import Panel from "../panel-general/Panel.vue";
import PanelSection from "../panel-general/PanelSection.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import JsonEditor from "../../inputs/JsonEditor.vue";

export default defineComponent({
  props: {
    pkgData: Object,
  },
  components: {
    Panel,
    PanelAttribute,
    JsonEditor,
  },
  setup(props, { emit }) {},
});
