
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { Spread, Layer } from "@bubblydoo/common";
import { render, TransfoSvgPredownloader, loadFonts } from "@bubblydoo/renderer";
import { resolveSpread, TransfoStoreClient, ExternalSpreadRegistry } from "@bubblydoo/resolver";
import { renderService } from "../../../renderer/render-service";
import artboardStyles from "!!raw-loader!sass-loader!./artboard-styles.scss";
import { TRANSFO_API_URL } from "@/config";

type ArtboardElement = HTMLElement;

export default defineComponent({
  props: [
    "spread",
    "runArguments",
    "snippets",
    "themes",
    "fonts",
    "externalSpreadRegistry",
    "maximumQuality",
    "transfoSvgPredownloader",
  ],
  setup(props) {
    const spread = computed<Spread>(() => props.spread);
    const externalSpreadRegistry = computed<ExternalSpreadRegistry>(
      () => props.externalSpreadRegistry
    );
    const canvasEl = ref<HTMLDivElement | null>(null);
    const shadowRoot = ref<ShadowRoot | null>(null);
    const rendererDone = ref<boolean>(false);
    const transfoClient = new TransfoStoreClient(TRANSFO_API_URL);
    const transfoSvgPredownloader = computed(
      () => props.transfoSvgPredownloader || new TransfoSvgPredownloader()
    );

    const runRenderer = async (spread: Spread) => {
      const root = shadowRoot.value;
      if (!root) return;

      rendererDone.value = false;

      console.log("resolving")
      const resolvedSpread = await resolveSpread(
        spread,
        {
          transfo: {
            storeUrl: TRANSFO_API_URL,
            maximumQuality: props.maximumQuality,
          },
          transfoClient,
        },
        externalSpreadRegistry.value
      );
      console.log("loading fonts")
      if (props.fonts) await loadFonts(props.fonts);
      console.log("rendering")
      await render(root, JSON.parse(JSON.stringify(resolvedSpread)), {
        runArguments: props.runArguments,
        snippets: props.snippets,
        themes: props.themes,
        transfoSvgPredownloader: transfoSvgPredownloader.value,
      });

      rendererDone.value = true;
      console.log("renderer done");

      // renderService.resultMappings = resultMappings;
    };

    // watch(spread, (spread) => {
    //   runRenderer(spread);
    // });

    watch(spread, () => {
      renderService.resultMappings = null;
    });

    onMounted(() => {
      renderService.resultMappings = null;

      const el = canvasEl.value;
      if (!el) return;

      const root = el.attachShadow({ mode: "open" });

      const sheet = new CSSStyleSheet();
      sheet.replaceSync(artboardStyles);
      root.adoptedStyleSheets = [sheet];

      shadowRoot.value = root;

      // runRenderer(spread.value);
    });

    return {
      canvasEl,
      transfoSvgPredownloader,
      render() {
        return runRenderer(spread.value);
      },
    };
  },
});
