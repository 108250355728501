import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05e73d62"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "layer-container"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayersPanelLayer = _resolveComponent("LayersPanelLayer")!
  const _component_LayersPanelModifiers = _resolveComponent("LayersPanelModifiers")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Layers",
    class: "panel"
  }, {
    default: _withCtx(() => [
      (_ctx.spread)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.spread)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_LayersPanelLayer, {
              layers: _ctx.spread.layers,
              depth: 0,
              onToggleSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSelect($event))),
              selectedLayers: _ctx.selectedLayers
            }, null, 8, ["layers", "selectedLayers"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_LayersPanelModifiers, {
        onAdd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addLayer($event))),
        onRemove: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeLayer())),
        onToggleSelectParent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleSelectSpread())),
        onSelectView: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectView())),
        parentType: _ctx.spreadSelected ? 'book' : 'spread'
      }, null, 8, ["parentType"])
    ]),
    _: 1
  }))
}