
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";
import { Spread, Layer, Stylesheet, TextLayer, Package, PackageData, View } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import LayersPanelLayer from "./LayersPanelLayer.vue";
import LayersPanelModifiers from "./LayersPanelModifiers.vue";
import { swapArrayElements } from "@/util/array";
import { VueDraggableNext } from "vue-draggable-next";

// interface LayerAndDepth {
//   // ugly impractical solution, we still need the layers array structure for managing the order and items realtime, we'll replace to inside the loop of the arrays (only necessary for css?)
//   layer: Layer;
//   depth: number;
// }

export default defineComponent({
  props: ["spread", "selection", "selectionType"],
  components: {
    Panel,
    LayersPanelLayer,
    LayersPanelModifiers,
    draggable: VueDraggableNext,
  },
  setup(props, { emit }) {
    const store = useStore();

    const selection = computed<Package | Spread | View | Layer>(() => props.selection as any);
    const selectionType = computed<"book" | "view" | "spread" | "layer" | null>(
      () => props.selectionType as any
    );

    const spread = computed<Spread>(() => props.spread);
    const selectedLayers = reactive(new Set<Layer>());
    const spreadSelected = ref(false);

    onMounted(() => {
      if (selectionType.value === "spread") spreadSelected.value = true;
      else if (selectionType.value === "layer") {
        selectedLayers.clear();
        selectedLayers.add(props.selection);
      }
    });

    watch(spread, () => {
      spreadSelected.value = true;
      selectedLayers.clear();
    });

    const selectedLayer = ref<Layer>();

    const getLayerParentLayers = (searchingLayer: Layer): Layer[] | false => {
      const traverse = (layers: Layer[]): Layer[] | false => {
        if (layers.includes(searchingLayer)) return layers;

        for (const childLayer of layers) {
          if (!("layers" in childLayer)) continue;
          const v = traverse(childLayer.layers);
          if (v) return v;
        }

        return false;
      };

      return traverse(spread.value.layers);
    };

    return {
      spread,
      selectedLayers,
      toggleSelect(params: any) {
        const { layer, event } = params;
        const multi = event.metaKey || event.ctrlKey;

        spreadSelected.value = false;
        if (multi) {
          selectedLayers.has(layer) ? selectedLayers.delete(layer) : selectedLayers.add(layer);
        } else {
          selectedLayers.clear();
          selectedLayers.add(layer);
        }

        selectedLayer.value = layer;

        emit("select", { type: "layer", thing: layer });
      },
      toggleSelectSpread() {
        selectedLayers.clear();
        spreadSelected.value = !spreadSelected.value;
        emit("select", { type: spreadSelected.value ? "spread" : "book" });
      },
      selectView() {
        selectedLayers.clear();
        spreadSelected.value = false;
        emit("select", { type: "view" });
      },
      spreadSelected,
      // addingLayer,
      addLayer(type: string) {
        let newLayer: Layer;
        if (type === "group") {
          newLayer = {
            type: "group",
            name: "Group",
            layers: [],
          };
        } else if (type === "transfo-svg") {
          newLayer = {
            type: "transfo-svg",
            name: "Transfo SVG",
            namespace: "",
            version: "",
          };
        } else if (type === "text") {
          newLayer = {
            type: "text",
            name: "Text",
            text: "",
            lineConfigurations: [],
          };
        } else if (type === "image") {
          newLayer = {
            type: "image",
            name: "Image",
            url: "",
          };
        } else if (type === "html") {
          newLayer = {
            type: "html",
            name: "Html",
            html: "<code>Hey</code>",
          };
        } else if (type === "external-spread") {
          newLayer = {
            type: "external-spread",
            name: "External Spread",
            ref: ""
          };
        } else {
          throw new Error("Unknown adding layer");
        }

        const p = selectedLayer.value;
        let parentLayers: Layer[];
        if (spreadSelected.value) {
          parentLayers = spread.value.layers;
        } else if (p && p.type === "group") {
          parentLayers = p.layers;
        } else {
          return;
        }

        parentLayers.push(newLayer!);
        emit("select", { type: "layer", thing: newLayer! });
        selectedLayer.value = newLayer;
        selectedLayers.clear();
        selectedLayers.add(newLayer);
      },
      removeLayer() {
        if (!confirm('Remove layer?')) return;
        const searchingLayer = selectedLayer.value;
        if (!searchingLayer) return false;
        const parentLayers = getLayerParentLayers(searchingLayer);
        if (!parentLayers) return;
        parentLayers.splice(parentLayers.indexOf(searchingLayer), 1);
        selectedLayer.value = undefined;
      },
      // up() {
      //   const searchingLayer = selectedLayer.value;
      //   if (!searchingLayer) return false;
      //   const parentLayers = getLayerParentLayers(searchingLayer);
      //   if (!parentLayers) return;
      //   const i = parentLayers.indexOf(searchingLayer);
      //   if (!parentLayers[i - 1] || !parentLayers[i]) return;
      //   swapArrayElements(parentLayers, i, i - 1);
      //   recalculateLayers(spread.value);
      // },
      // down() {
      //   const searchingLayer = selectedLayer.value;
      //   if (!searchingLayer) return false;
      //   const parentLayers = getLayerParentLayers(searchingLayer);
      //   if (!parentLayers) return;
      //   const i = parentLayers.indexOf(searchingLayer);
      //   if (!parentLayers[i + 1] || !parentLayers[i]) return;
      //   swapArrayElements(parentLayers, i, i + 1);
      //   recalculateLayers(spread.value);
      // },
      // log(event: any) {
      //   console.log(event);
      //   console.log(spread.value.layers);
      // },
    };
  },
});
