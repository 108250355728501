
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import MonacoWrapper from "../MonacoWrapper.vue";
import css from "css";
import { Stylesheet } from "@bubblydoo/common";
import shallowEqual from "shallowequal";

const DEFAULT_CSS_STRING = ":host {\n  \n}";

export default defineComponent({
  props: {
    modelValue: Object,
  },
  components: {
    MonacoWrapper,
  },
  setup(props, { emit }) {
    const cssString = ref<string>(DEFAULT_CSS_STRING);
    let internalStylesheet: Stylesheet = {};

    const cssToStylesheet = (_css: string) => {
      let json = css.parse(_css);

      const rule = json.stylesheet!.rules.find((r: css.Rule) =>
        r.selectors!.includes(":host")
      ) as css.Rule;

      const sheet: Stylesheet = {};

      for (let declaration of rule!.declarations! as css.Declaration[]) {
        const prop = declaration.property;
        const value = declaration.value;
        if (prop && value) sheet[prop] = value;
      }

      return sheet;
    };

    watch(cssString, (_css) => {
      try {
        const sheet = _css ? cssToStylesheet(_css) : {};
        // internalStylesheet will only be updated in conjunction with an emit
        if (shallowEqual(sheet, internalStylesheet)) return;
        // emit will also cause the v-model to provide another update
        // by setting internalStylesheet, we can check if the stylesheet originated
        // from internally or externally
        internalStylesheet = sheet;
        emit("update:modelValue", { ...sheet });
      } catch (e) {
        // Invalid css
      }
    });

    const stylesheetToCss = (sheet: Stylesheet) => {
      let json = {
        type: "stylesheet",
        stylesheet: {
          rules: [
            {
              type: "rule",
              selectors: [":host"],
              declarations: Object.entries(sheet)
                .filter(([key, value]) => !!value) // might be undefined or null
                .map(([key, value]) => ({
                  type: "declaration",
                  property: key,
                  value: value,
                })),
            },
          ],
        },
      };
      let _css = css.stringify(json);
      return _css || DEFAULT_CSS_STRING;
    };

    const stylesheet = computed(() => (props.modelValue || {}) as Stylesheet);

    const syncStylesheetToCss = () => {
      cssString.value = stylesheetToCss(stylesheet.value || {});
    };

    watch(stylesheet, (sheet) => {
      if (shallowEqual(internalStylesheet, sheet)) return;
      // stylesheet changed externally
      console.log("[StylesheetEditor] stylesheet externally changed", sheet);
      syncStylesheetToCss();
      internalStylesheet = sheet || {};
    });

    onMounted(() => {
      syncStylesheetToCss();
      internalStylesheet = stylesheet.value || {};
    });

    return {
      cssString,
    };
  },
});
