
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";
import { SpreadFormat } from "@bubblydoo/common";
import shallowEqual from "shallowequal";

export default defineComponent({
  props: {
    modelValue: Object,
  },
  components: {},
  setup(props, { emit }) {
    const internalFormat = ref<SpreadFormat>({ ...props.modelValue } as SpreadFormat);
    const format = computed(() => props.modelValue! as SpreadFormat);

    watch(format, (f) => {
      if (shallowEqual(internalFormat.value, f)) return;
      internalFormat.value = { ...f };
    });

    watch(
      () => internalFormat.value && internalFormat.value.width,
      () => emitUpdates()
    );
    watch(
      () => internalFormat.value && internalFormat.value.height,
      () => emitUpdates()
    );

    const emitUpdates = () => {
      if (shallowEqual(internalFormat.value, format.value)) return;
      emit("update:modelValue", { ...internalFormat.value });
    };

    return {
      internalFormat,
      swap() {
        const { width, height } = internalFormat.value;
        internalFormat.value = {
          width: height,
          height: width,
        };
      },
    };
  },
});
