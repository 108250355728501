
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";
import { SpreadFormat } from "@bubblydoo/common";
import shallowEqual from "shallowequal";

export default defineComponent({
  props: {
    modelValue: String,
  },
  components: {},
  setup(props, { emit }) {
    const preserve = ref(true);
    const size = ref<"meet" | "slice">("meet");
    const xAlignment = ref<"xMin" | "xMid" | "xMax">("xMid");
    const yAlignment = ref<"YMin" | "YMid" | "YMax">("YMid");

    const sizeInput = [
      {
        key: "fit",
        value: "fit",
      },
      {
        key: "cover",
        value: "cover",
      },
    ];

    const xInput = {
      key: "size",
      value: "size",
    };

    const yInput = {
      key: "size",
      value: "size",
    };

    const emitUpdates = () => {
      let v: string;
      if (!preserve.value) {
        v = "none";
      } else {
        v = `${xAlignment.value}${yAlignment.value} ${size.value}`;
      }
      emit("update:modelValue", v);
    };

    [preserve, size, xAlignment, yAlignment].map((ref) => watch(ref, emitUpdates));

    const parseValue = (value: string | undefined) => {
      const isNone = value === "none";
      preserve.value = !isNone;
      if (!isNone) {
        const s = (value || "").split(" ");
        const x = s[0].slice(0, 4);
        const y = s[0].slice(4, 8);
        size.value = (s[1] as "meet" | "slice") || "meet";
        xAlignment.value = (x as "xMin" | "xMid" | "xMax") || "xMid";
        yAlignment.value = (y as "YMin" | "YMid" | "YMax") || "YMid";
      }
    };

    watch(() => props.modelValue, parseValue);

    parseValue(props.modelValue);

    return {
      preserve,
      size,
      xAlignment,
      yAlignment,
      sizeInput,
    };
  },
});
