import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29baa096"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "slider-input-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.internalLcs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            class: "slider is-fullwidth",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.lineConfigurationsCount = $event)),
            type: "range",
            step: "1",
            min: "1",
            max: "6"
          }, null, 512), [
            [
              _vModelText,
              _ctx.lineConfigurationsCount,
              void 0,
              { number: true }
            ]
          ]),
          _withDirectives(_createElementVNode("input", {
            class: "input is-small",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.lineConfigurationsCount = $event)),
            type: "number",
            min: "1",
            max: "100"
          }, null, 512), [
            [
              _vModelText,
              _ctx.lineConfigurationsCount,
              void 0,
              { number: true }
            ]
          ])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.internalLcs.slice(0, _ctx.lineConfigurationsCount), (lc, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "line-config-line"
      }, [
        _createVNode(_component_Line, {
          text: _ctx.splitText[i],
          modelValue: _ctx.internalLcs[i],
          "onUpdate:modelValue": ($event: any) => (_ctx.internalLcs[i] = $event),
          index: i,
          advancedMode: _ctx.advancedMode
        }, null, 8, ["text", "modelValue", "onUpdate:modelValue", "index", "advancedMode"])
      ]))
    }), 128))
  ], 64))
}