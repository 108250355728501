
/// <reference path="../../types/perspective-transform.d.ts"/>
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";
import pt from "perspective-transform";
import { renderService } from "@/renderer/render-service";
import { Layer } from "@bubblydoo/common";
import { ResultMapping } from "@bubblydoo/renderer";
import { multiply, Matrix, matrix, inv } from "mathjs";

export default defineComponent({
  props: {
    modelValue: String,
    layer: Object,
  },
  components: {},
  setup(props, { emit }) {
    const srcPointsText = ref("0,0,100,0,0,100,100,100");
    const dstPointsText = ref("0,0,100,0,0,100,100,100");
    const srcPoints = computed(() => srcPointsText.value.split(/;|,/g).map((x) => +x));
    const dstPoints = computed(() => dstPointsText.value.split(/;|,/g).map((x) => +x));

    const layer = computed(() => props.layer as Layer);

    let clickingElement: HTMLElement | SVGElement | null = null;
    let clickingPoints: number[] = [];
    const clickHandler = (ev: MouseEvent) => {
      const maskEl = document.querySelector(".artboard-viewer-mask")!;
      if (ev.target !== maskEl) return;
      const box = maskEl.getBoundingClientRect();
      const origX = ev.clientX - box.left;
      const origY = ev.clientY - box.top;
      const mat = multiply<Matrix>(inv(renderService.matrix!), matrix([origX, origY, 1]));
      const [x, y] = mat.toArray() as number[];
      clickingPoints.push(x, y);

      const pointEl = document.createElement("div");
      pointEl.style.left = `${origX}px`;
      pointEl.style.top = `${origY}px`;
      pointEl.style.position = "absolute";
      pointEl.style.width = "10px";
      pointEl.style.height = "10px";
      pointEl.style.background = "hotpink";
      pointEl.style.opacity = "0.5";
      pointEl.style.pointerEvents = "none";
      maskEl.appendChild(pointEl);

      if (clickingPoints.length >= 8) {
        window.removeEventListener("mousedown", clickHandler);
        clickingElement!.style.transform = null as any;
        const rect = clickingElement!.getBoundingClientRect();
        let { top, left, width, height } = rect;
        top = top - box.top;
        left = left - box.left;
        const invMat = inv(renderService.matrix!);
        const transferToArtboard = ([x, y]: [number, number]) =>
          multiply(invMat, matrix([x, y, 1])).toArray() as number[];

        const [tlX, tlY] = transferToArtboard([left, top]);
        const [trX, trY] = transferToArtboard([left + width, top]);
        const [blX, blY] = transferToArtboard([left, top + height]);
        const [brX, brY] = transferToArtboard([left + width, top + height]);

        srcPointsText.value = [tlX, tlY, trX, tlY, blX, blY, brX, brY].join(",");
        dstPointsText.value = clickingPoints.join(",");
        clickingPoints = [];
        clickingElement = null;
      }
    };

    return {
      srcPointsText,
      dstPointsText,
      srcPoints,
      dstPoints,
      calculate() {
        const r = pt(srcPoints.value, dstPoints.value);
        const matrix3x3 = r.coeffs;
        const t = matrix3x3;

        // from https://github.com/jlouthan/perspective-transform/blob/master/examples/css-matrix3d/main.js
        // https://stackoverflow.com/questions/36050537/how-to-match-3d-perspective-of-real-photo-and-object-in-css3-3d-transforms
        // prettier-ignore
        const matrix4x4 = [
          t[0], t[3], 0,  t[6],
          t[1], t[4], 0,  t[7],
          0   , 0   , 1,     0,
          t[2], t[5], 0,  t[8],
        ];

        const cssTransform = `matrix3d(${matrix4x4.join(", ")})`;

        emit("update:modelValue", cssTransform);
      },
      clickCalculate() {
        clickingPoints = [];
        clickingElement = null;
        const mappings = renderService.resultMappings;
        if (!mappings) return;
        let mapping: ResultMapping;
        function recursiveFindLayer(mappings: ResultMapping[]) {
          for (const m of mappings!) {
            if (m.layer === layer.value) {
              mapping = m;
              break;
            }
            if (!mapping && m.layersMappings) recursiveFindLayer(m.layersMappings);
          }
        }
        recursiveFindLayer(mappings);
        clickingElement = mapping!.element!;
        // debugger;
        // const box1 = clickingElement.getBoundingClientRect();
        // const r1 = multiply<Matrix>(inv(renderService.matrix!), matrix([box1.width, box1.height, 1]));
        // const r2 = multiply<Matrix>(inv(renderService.matrix!), matrix([box1.x, box1.y, 1]));
        // debugger;
        alert("Now click in the 4 corners in Z shape");
        window.addEventListener("mousedown", clickHandler);

        clickingElement!.style.transform = null as any;
        clickingElement!.style.opacity = "0.1";
        clickingElement!.style.outline = "3px solid hotpink";
        const maskEl = document.querySelector(".artboard-viewer-mask")!;
        maskEl.innerHTML = "";
      },
    };
  },
});
