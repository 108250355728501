
/// <reference types="resize-observer-browser"/>
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { Spread } from "@bubblydoo/common";
import Artboard from "./Artboard.vue";
import { renderService } from "@/renderer/render-service";
import { matrix, multiply, Matrix, identity, add } from "mathjs";
import { TransfoSvgPredownloader } from "@bubblydoo/renderer";
import { ExternalSpreadRegistry } from "@bubblydoo/resolver/dist/resolver";

const PX_PER_IN = 96;
const MM_PER_IN = 25.4;
const MM_PER_PX = PX_PER_IN / MM_PER_IN;

export default defineComponent({
  components: {
    Artboard,
  },
  props: [
    "spread",
    "runArguments",
    "snippets",
    "themes",
    "fonts",
    "externalSpreadRegistry",
    "maximumQuality",
    "transfoSvgPredownloader",
  ],
  setup(props) {
    const spread = ref<Spread>(props.spread);
    const externalSpreadRegistry = computed<ExternalSpreadRegistry>(
      () => props.externalSpreadRegistry
    );

    const artboardEl = ref<HTMLDivElement | null>(null);
    const artboardMoverEl = ref<HTMLDivElement | null>(null);
    const artboardZoomerEl = ref<HTMLDivElement | null>(null);
    const artboardWorkspaceEl = ref<HTMLDivElement | null>(null);
    const artboardComponent = ref<any>(null);

    const transfoSvgPredownloader = computed(
      () => props.transfoSvgPredownloader || new TransfoSvgPredownloader()
    );

    const recalculate = () => {
      const el = artboardEl.value;
      const moverEl = artboardMoverEl.value;
      const zoomerEl = artboardZoomerEl.value;
      const workspaceEl = artboardWorkspaceEl.value;
      if (!el || !zoomerEl || !workspaceEl || !moverEl || !spread.value) return;
      const { format } = spread.value;
      el.style.height = `${format.height}mm`;
      el.style.width = `${format.width}mm`;
      const artboardRect = {
        width: format.width * MM_PER_PX,
        height: format.height * MM_PER_PX,
      };
      zoomerEl.style.transform = "";
      delete (zoomerEl as any).style.transform;
      console.log("spread rect", artboardRect);
      const workspaceRect = workspaceEl.getBoundingClientRect();
      console.log("workspace rect", workspaceRect);
      const margin = 20;
      const widthScaler = (workspaceRect.width - 2 * margin) / artboardRect.width;
      const heightScaler = (workspaceRect.height - 2 * margin) / artboardRect.height;
      const scaler = Math.min(widthScaler, heightScaler);
      zoomerEl.style.transform = `scale(${scaler})`;
      moverEl.style.transform = `translateX(${margin}px) translateY(${margin}px)`;

      const scalingMatrix: Matrix = multiply(identity(3), scaler) as Matrix;
      const translationMatrix: Matrix = matrix([
        [1, 0, margin],
        [0, 1, margin],
        [0, 0, 1],
      ]);
      renderService.matrix = multiply(translationMatrix, scalingMatrix);
      console.log("Artboard matrix", renderService.matrix);
    };

    const resizeObserver = new ResizeObserver((entries) => {
      recalculate();
    });

    onMounted(() => {
      resizeObserver.observe(artboardWorkspaceEl.value!);
      recalculate();
    });

    watch(artboardEl, (el) => {
      recalculate();
    });

    watch(
      () => props.spread,
      (v) => {
        spread.value = v;
        recalculate();
      }
    );

    return {
      spread,
      artboardEl,
      artboardMoverEl,
      artboardZoomerEl,
      artboardWorkspaceEl,
      artboardComponent,
      transfoSvgPredownloader,
      externalSpreadRegistry,
      render() {
        recalculate();
        return artboardComponent.value?.render();
      },
    };
  },
});
