import { Project, Package } from "@bubblydoo/common";

export interface RootState {
  projects: Project[] | null;
  currentProject: Project | null;
  currentPackage: Package | null;
}

export const state: RootState = {
  projects: null,
  currentProject: null,
  currentPackage: null
};
