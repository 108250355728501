
import { defineComponent, computed, ref, onMounted } from "vue";
import { useStore } from "@/store/store";
import semverSort from "semver/functions/sort";
import { Project, Package } from "@bubblydoo/common";
import ProjectsChooserProject from "./ProjectsPanelProject.vue";
import { MutationTypes } from "@/store/mutation-types";
import { ActionTypes } from "@/store/action-types";

export default defineComponent({
  props: ["pkgData"],
  components: {
    ProjectsChooserProject,
  },
  setup(props, { emit }) {
    const store = useStore();

    const projects = computed(() => {
      const projs = store.getters.projects;
      if (!projs) return null;

      const d = (p: any) => {
        return +new Date(p.packageUpdatedAt || p.createdAt || p.updatedAt || "1970-01-01T00:00:00");
      };

      return [...projs].filter(
        (project) => {
          return searchByID ? project.name.toLowerCase().includes(projectQuery.value.toLowerCase())
            : project.id.toLowerCase().includes(projectQuery.value.toLowerCase())
        }).sort((a, b) => d(b) - d(a));
    });

    const newProjectName = ref<string>();
    const duplicateProjectName = ref<string>();

    const searchByID = ref<boolean>(false);
    const showHidden = ref<boolean>(false);
    const toggleDuplicate = ref<boolean>(false);
    const toDuplicateProject = ref<{ projectName: string, packageId: string, version: string } | null>(null);

    const projectQuery = ref<string>('');

    const refresh = () => {
      store.dispatch(ActionTypes.FETCH_PROJECTS);
    };
    onMounted(() => refresh());

    const createSkeletonPackage = (projectId: string) => {
      const pkg: Package = {
        id: null!,
        projectId,
        version: null!,
        data: {
          views: {},
          snippets: [],
          themes: [],
          previewArguments: [],
          previewSettings: {},
          schema: {},
          fonts: [],
        },
      };
      return pkg;
    };

    const selectToDuplicate = async (obj: { projectName: string, packageId: string, version: string }) => {
      console.log("Selected", obj);
      toDuplicateProject.value = obj;
      duplicateProjectName.value = obj.projectName + " (copy)";
      toggleDuplicate.value = true;
    };

    const addPkg = async (project: Project) => {
      await store.dispatch(ActionTypes.FETCH_PROJECT, project.id);
      store.commit(MutationTypes.SET_CURRENT_PACKAGE, createSkeletonPackage(project.id));
    };

    const addProjectTag = async (obj: { packageId: string; tagName: string }, project: Project) => {
      await store.dispatch(ActionTypes.CREATE_PROJECT_TAG, {
        projectId: project.id,
        packageId: obj.packageId,
        tagName: obj.tagName,
      });
    };

    const updateProject = async (project: Project) => {
      await store.dispatch(ActionTypes.UPDATE_PROJECT, project);
    };

    const turnOffDuplication = () => {
      toggleDuplicate.value = false;
      toDuplicateProject.value = null;
      newProjectName.value = "";
    }

    return {
      projects,
      newProjectName,
      projectQuery,
      toggleDuplicate, showHidden,
      toDuplicateProject, duplicateProjectName, searchByID,
      async loadPkg(pkg: Package, project: Project) {
        // need to await, weird errors if pkg is set before project
        await store.dispatch(ActionTypes.FETCH_PROJECT, project.id);
        if (!pkg) {
          store.commit(MutationTypes.SET_CURRENT_PACKAGE, createSkeletonPackage(project.id));
        } else {
          await store.dispatch(ActionTypes.FETCH_PACKAGE, pkg.id);
        }
        emit("update:activeWorkspace", "Default"); // should be latest active workspace
      },
      addPkg,
      addProjectTag,
      updateProject,
      refresh, turnOffDuplication,
      async duplicate() {

        const newProject: Partial<Project> = {
          id: null!,
          name: duplicateProjectName?.value || toDuplicateProject.value!.projectName,
        };


        const currentPackage = await store.dispatch(ActionTypes.FETCH_PACKAGE, toDuplicateProject.value!.packageId);

        const duplicatedProject = await store.dispatch(ActionTypes.CREATE_PROJECT, newProject);
        console.log("Duplicated project", duplicatedProject);

        currentPackage.version = "0.0.0"
        currentPackage.projectId = duplicatedProject.id;
        currentPackage.createdAt = undefined;
        console.log("Duplicated package", currentPackage);

        const savedPackage = await store.dispatch(ActionTypes.CREATE_PACKAGE, currentPackage);
        console.log("Saved package", savedPackage);
        await store.dispatch(ActionTypes.FETCH_PROJECT, duplicatedProject.id);

        store.commit(MutationTypes.SET_CURRENT_PACKAGE, savedPackage);
        turnOffDuplication();

      },
      selectToDuplicate,

      async add() {
        const project: Partial<Project> = {
          id: null!,
          name: newProjectName.value!,
        };
        const savedProject = await store.dispatch(ActionTypes.CREATE_PROJECT, project);
        addPkg(savedProject);
        // go to default workspace
        emit("update:activeWorkspace", "Default"); // should be latest active workspace
      },
    };
  },
});
