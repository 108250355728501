
import { defineComponent, ref, reactive, computed, watch, watchEffect } from "vue";
import Workspace from "./Workspace.vue";
import { workspaces } from "./workspaces";
import ProjectsPanel from "../panels/projects-panel/ProjectsPanel.vue";
import ViewPanel from "../panels/view-panel/ViewPanel.vue";
import ArtboardPanel from "../panels/artboard-panel/ArtboardPanel.vue";
import ToolsPanel from "../panels/tools-panel/ToolsPanel.vue";
import LayersPanel from "../panels/layers-panel/LayersPanel.vue";
import PropertiesPanel from "../panels/properties-panel/PropertiesPanel.vue";
import SnippetsPanel from "../panels/snippets-panel/SnippetsPanel.vue";
import PreviewPanel from "../panels/preview-panel/PreviewPanel.vue";
import ThemesPanel from "../panels/themes-panel/ThemesPanel.vue";
import SchemaPanel from "../panels/schema-panel/SchemaPanel.vue";
import ExportPanel from "../panels/export-panel/ExportPanel.vue";
import ExportMultiplePanel from "../panels/export-multiple-panel/ExportMultiplePanel.vue";
import { useStore } from "../../store/store";
import { Spread, Layer, PreviewArgument, Package, View } from "@bubblydoo/common";
import { TransfoSvgPredownloader } from "@bubblydoo/renderer";
import { buildExternalSpreadRegistry } from "@bubblydoo/resolver";

export default defineComponent({
  props: ["title", "pickedArgument"],
  components: {
    Workspace,
    ArtboardPanel,
    ToolsPanel,
    ProjectsPanel,
    ViewPanel,
    LayersPanel,
    PropertiesPanel,
    SnippetsPanel,
    ThemesPanel,
    PreviewPanel,
    SchemaPanel,
    ExportPanel,
    ExportMultiplePanel,
  },
  setup(props, { emit }) {
    const store = useStore();

    const activeWorkspace = ref(workspaces[0]);

    // const spread = computed<Spread>(() => props.spread);
    const spread = ref<Spread | null>(null);
    const view = ref<View | null>(null);
    const rendering = ref<boolean>(false);
    const selectedThingType = ref<"book" | "view" | "spread" | "layer" | null>(null);
    const selectedThing = ref<Package | View | Spread | Layer | null>(null);
    const currentPackage = computed(() => store.getters.currentPackage);
    const live = ref(false);
    const liveInterval = ref(1000);

    watch(currentPackage, (pkg) => {
      if (!pkg) {
        selectedThingType.value = null;
        selectedThing.value = null;
        pickedPreviewArgument.value = null;
        activeWorkspace.value = workspaces[0];
      } else if (!selectedThingType.value) {
        selectedThingType.value = "book";
        selectedThing.value = pkg;
      }

      if (pkg && pkg.data.previewArguments && pkg.data.previewArguments.length) {
        pickedPreviewArgument.value = pkg.data.previewArguments[0];
      }
    });

    const artboardViewerComponent = ref<any>(null);
    const pickedPreviewArgument = ref<PreviewArgument | null>(null);
    const selectedViewKey = ref<string | null>(null);

    const transfoSvgPredownloader = ref(new TransfoSvgPredownloader());

    function play() {
      if (!artboardViewerComponent.value) return;

      const pkg = currentPackage.value;
      if (pkg) setTimeout(() => {
        window.localStorage.setItem('builderPackage', JSON.stringify(pkg));
      }, 1000);

      console.log('setting rendering to true');
      rendering.value = true;
      try {
        return artboardViewerComponent.value.render().then(() => {
          rendering.value = false;
        });
      } catch (err) {
        console.error(err);
      }
    }

    watch(pickedPreviewArgument, (p) => {
      emit("update:pickedPreviewArgument", pickedPreviewArgument);
      if (pickedPreviewArgument) play();
    });

    function toggleLive() {
      live.value = !live.value
    }

    watchEffect(async (onCleanup) => {
      if (!live.value) return;
      let timeoutId: number | null = null;
      let ignore = false;
      const f = async () => {
        if (ignore) return;
        console.log('play')
        await play()
        timeoutId = setTimeout(f, liveInterval.value) as any as number;
      }
      onCleanup(() => {
        ignore = true;
        if (timeoutId) clearTimeout(timeoutId)
      });
      f();
    });

    return {
      rendering,
      live,
      toggleLive,
      liveInterval,
      workspaces,
      workspacesWithoutExplorer: workspaces.filter((w, i) => i !== 0),
      activeWorkspace,
      toggleSelect(workspace: any) {
        activeWorkspace.value = workspace;
        emit("update:activeWorkspace", activeWorkspace);
      },
      isActive(checkWorkspace: any) {
        return activeWorkspace.value.name === checkWorkspace.name;
      },
      currentPackage,
      pkgData: computed(() => currentPackage.value && currentPackage.value.data),
      externalSpreadRegistry: computed(
        () => currentPackage.value && buildExternalSpreadRegistry(currentPackage.value.data.views)
      ),
      spread,
      selectedThing,
      selectedThingType,
      artboardViewerComponent,
      selectedViewKey,
      transfoSvgPredownloader,
      play,
      selectSpread(s: Spread) {
        console.log("selected spread", s);
        spread.value = s;
        selectedThingType.value = "spread";
        selectedThing.value = s;
      },
      selectView(vk: string) {
        console.log("selected view", vk);
        selectedViewKey.value = vk;
        const v = currentPackage.value?.data.views[vk]!;
        view.value = v;
        selectedThingType.value = "view";
        selectedThing.value = v;
      },
      jsonify(s: string) {
        return JSON.stringify(s, null, 2);
      },
      selected(o: { type: "book" } | { type: "view" } | { type: "spread" } | { type: "layer"; thing: Layer }) {
        selectedThingType.value = o.type;
        if (o.type === "layer") {
          selectedThing.value = o.thing;
        } else if (o.type === "spread") {
          selectedThing.value = spread.value;
        } else if (o.type === "view") {
          selectedThing.value = view.value;
        } else if (o.type === "book") {
          selectedThing.value = store.getters.currentPackage;
        } else {
          selectedThing.value = null;
        }
        console.log(selectedThing.value, selectedThingType.value);
      },
      pickedPreviewArgument,
    };
  },
});
