
import { defineComponent, ref, reactive, computed, watch, onMounted, watchEffect } from "vue";
import { useStore } from "@/store/store";
import { SpreadFormat, Cropping } from "@bubblydoo/common";
import isEqualDeep from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import JsonEditor from "./JsonEditor.vue";

export default defineComponent({
  props: {
    modelValue: Object,
  },
  components: {
    JsonEditor,
  },
  setup(props, { emit }) {
    const croppings = computed(() => props.modelValue as Cropping[]);
    const internalCroppings = ref<Cropping[] | undefined>(props.modelValue as Cropping[]);

    watch(croppings, (cs) => {
      if (isEqualDeep(cs, internalCroppings.value)) return;
      internalCroppings.value = cloneDeep(cs);
    });

    const emitValues = (cs: Cropping[]) => {
      if (isEqualDeep(croppings.value, cs)) return;
      console.log("[CroppingsEditor] update");
      emit("update:modelValue", cloneDeep(cs));
    };

    watch(internalCroppings, (cs) => {
      emitValues(cs || []);
    });

    watchEffect(() => {
      emitValues(internalCroppings.value || []);
    });

    return {
      internalCroppings,
      addCropping() {
        const cs = internalCroppings.value || [];
        internalCroppings.value = [
          ...cs,
          { name: `cropping${cs.length}`, dimensions: { top: 0, left: 0, right: 0, bottom: 0 } },
        ];
      },
    };
  },
});
