
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";

export default defineComponent({
  props: {
    modelValue: String, // ex. 15px or 50mm
    label: String,
  },
  components: {},
  setup(props, { emit }) {
    const dimension = ref("");
    const unit = ref("mm");
    const label = computed(() => props.label || "");
    const fallback = ref(false);
    const internalValue = ref(props.modelValue);

    const handleValue = (value: string) => {
      internalValue.value = value;
      const values = value.match(/(\d+)([a-z%]*)/);
      const [regexDimension, regexUnit] = values ? [values[1], values[2]] : [];
      dimension.value = regexDimension || "";
      unit.value = regexUnit || "";
      fallback.value = !regexDimension;
    };

    const initialize = (value?: string) => {
      if (value) {
        handleValue(value);
      } else {
        dimension.value = "";
        unit.value = "";
      }
    };

    initialize(props.modelValue); // at component creation

    watch(
      () => props.modelValue,
      (value) => {
        initialize(value); // when parent updates the value
      }
    );

    const sendUpdates = () => {
      const val = fallback.value ? internalValue.value : `${dimension.value}${unit.value}`;
      emit("update:modelValue", val);
    };

    watch(dimension, () => {
      const regex = /[^0-9]/g;
      if (dimension.value.match(regex)) {
        dimension.value = dimension.value.replace(regex, "");
      }
      sendUpdates();
    });

    watch(unit, () => {
      const regex = /[^a-z%]/g;
      if (unit.value.match(regex)) {
        unit.value = unit.value.replace(regex, "");
      }
      sendUpdates();
    });

    watch(internalValue, () => {
      sendUpdates();
    });

    return {
      dimension,
      label,
      unit,
      fallback,
      internalValue,
    };
  },
});
