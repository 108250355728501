
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";
import { Layer, Spread, GroupLayer } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import { swapArrayElements } from "@/util/array";

export default defineComponent({
  props: ["spread"],
  components: {
    Panel,
  },
  setup(props, { emit }) {
    return {};
  },
});
