
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";

export default defineComponent({
  props: ["parentType"],
  setup(props, { emit }) {
    const addingLayer = ref<string | null>(null);
    return {
      addingLayer,
      add() {
        emit("add", addingLayer.value);
      },
      remove() {
        emit("remove");
      },
      toggleSelectParent() {
        emit("toggle-select-parent");
      },
      selectView() {
        emit("select-view");
      }
    };
  },
});
