export const workspaces = [
  {
    name: "Explorer",
    columns: "auto 870px auto",
    rows: "auto 75% auto",
    styles: [
      [
        "background",
        "radial-gradient( circle farthest-corner at 22.4% 21.7%,  rgba(4,189,228,1) 0%, rgba(2,83,185,1) 100.2% )",
      ],
    ],
    spaces: [
      {
        name: "ProjectsSpace",
        column: "2",
        row: "2",
        styles: [
          ["padding", "15px"],
          ["background", "#161616"],
          ["border-radius", "15px"],
          ["overflow", "hidden"],
        ],
        panels: [["Projects", "projectsPanel"]],
      },
    ],
  },
  {
    name: "Default",
    columns: "60px auto 400px",
    rows: "calc(100% - 42px) 42px",
    spaces: [
      {
        name: "SelectorSpace",
        column: "3",
        row: "1 / 3",
        panels: [
          ["Layers", "layersPanel"],
          ["Properties", "propertiesPanel"],
        ],
      },
      {
        name: "ArtboardSpace",
        column: "2",
        row: "1",
        panels: [["Artboard", "artboardPanel"]],
      },
      {
        name: "ViewSpace",
        column: "2",
        row: "2",
        panels: [["View", "viewPanel"]],
      },
      {
        name: "ToolSpace",
        column: "1",
        row: "1 / 3",
        panels: [["Tools", "toolsPanel"]],
      },
    ],
  },
  {
    name: "Text",
    columns: "60px auto 400px",
    rows: "calc(100% - 42px) 42px",
    spaces: [
      {
        name: "SelectorSpace",
        column: "3",
        row: "1 / 3",
        panels: [["Layers", "layersPanel"]],
      },
      {
        name: "ArtboardSpace",
        column: "2",
        row: "1",
        panels: [["Artboard", "artboardPanel"]],
      },
      {
        name: "ViewSpace",
        column: "2",
        row: "2",
        panels: [["View", "viewPanel"]],
      },
      {
        name: "ToolSpace",
        column: "1",
        row: "1 / 3",
        panels: [["Tools", "toolsPanel"]],
      },
    ],
  },
  {
    name: "Snippets",
    columns: "100%",
    rows: "100%",
    spaces: [
      {
        name: "SnippetSpace",
        column: "1",
        row: "1",
        panels: [["Snippets", "snippetsPanel"]],
      },
    ],
  },
  {
    name: "Themes",
    columns: "100%",
    rows: "100%",
    spaces: [
      {
        name: "ThemesSpace",
        column: "1",
        row: "1",
        panels: [["Themes", "themesPanel"]],
      },
    ],
  },
  {
    name: "Preview",
    columns: "auto 40%",
    rows: "calc(100% - 42px) 42px",
    spaces: [
      {
        name: "PreviewSpace",
        column: "2",
        row: "1 / 3",
        panels: [["Preview", "previewPanel"]],
      },
      {
        name: "ArtboardSpace",
        column: "1",
        row: "1",
        panels: [["Artboard", "artboardPanel"]],
      },
      {
        name: "ViewSpace",
        column: "1",
        row: "2",
        panels: [["View", "viewPanel"]],
      },
    ],
  },
  {
    name: "Export",
    columns: "auto 40%",
    rows: "calc(100% - 42px) 42px",
    spaces: [
      {
        name: "ExportSpace",
        column: "2",
        row: "1 / 3",
        panels: [["Export", "exportPanel"]],
      },
      {
        name: "ArtboardSpace",
        column: "1",
        row: "1",
        panels: [["Artboard", "artboardPanel"]],
      },
      {
        name: "ViewSpace",
        column: "1",
        row: "2",
        panels: [["View", "viewPanel"]],
      },
    ],
  },
  {
    name: "Export Multiple",
    columns: "auto 40%",
    rows: "calc(100% - 42px) 42px",
    spaces: [
      {
        name: "ExportSpace",
        column: "2",
        row: "1 / 3",
        panels: [["Export", "exportMultiplePanel"]],
      },
      {
        name: "ArtboardSpace",
        column: "1",
        row: "1",
        panels: [["Artboard", "artboardPanel"]],
      },
      {
        name: "ViewSpace",
        column: "1",
        row: "2",
        panels: [["View", "viewPanel"]],
      },
    ],
  },
];
