
import { defineComponent, ref } from "vue";
import PlatformNavbar from "../components/PlatformNavbar.vue";
import WorkspaceContainer from "../components/workspaces/WorkspaceContainer.vue";
import { PreviewArgument } from "@bubblydoo/common";

export default defineComponent({
  components: {
    PlatformNavbar,
    WorkspaceContainer,
  },
  setup() {
    const workspaceContainer = ref<any>(null);
    const pickedPreviewArgument = ref<PreviewArgument | null>(null);

    return {
      workspaceContainer,
      pickedPreviewArgument,
    };
  },
});
