import { ActionContext } from "vuex";
import { RootState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import type { Project, Package } from "@bubblydoo/common";
import axios from "../http-commons";
import { STORE_BASE_URL } from "@/config";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<RootState, RootState>, "commit">;

export interface Actions {
  [ActionTypes.FETCH_PROJECTS](
    { commit }: AugmentedActionContext,
    payload?: void
  ): Promise<Project[]>;
  [ActionTypes.FETCH_PROJECT](
    { commit }: AugmentedActionContext,
    projectId: string
  ): Promise<Project>;
  [ActionTypes.FETCH_PACKAGE](
    { commit }: AugmentedActionContext,
    packageId: string
  ): Promise<Package>;
  [ActionTypes.CREATE_PROJECT](
    { commit }: AugmentedActionContext,
    project: Partial<Project>
  ): Promise<Project>;
  [ActionTypes.CREATE_PACKAGE](
    { commit }: AugmentedActionContext,
    pkg: Partial<Package>
  ): Promise<Package>;
  [ActionTypes.CREATE_PROJECT_TAG](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; packageId: string; tagName: string }
  ): Promise<Project>;
  [ActionTypes.UPDATE_PROJECT](
    { commit }: AugmentedActionContext,
    pkg: Partial<Project>
  ): Promise<Project>;
  [action: string]: ({ commit }: AugmentedActionContext, payload?: any) => Promise<any>;
}

export const actions: Actions = {
  [ActionTypes.FETCH_PROJECTS]: async ({ commit }) => {
    const promise = axios.get(`${STORE_BASE_URL}/projects`);
    promise.catch((err) => {
      alert("Error fetching projects");
    });
    return promise.then((resp) => {
      commit(MutationTypes.SET_PROJECTS, resp.data);
      return resp.data;
    });
  },
  [ActionTypes.FETCH_PROJECT]: async ({ commit }, projectId) => {
    const projectResp = await axios.get(`${STORE_BASE_URL}/projects/${projectId}`);
    commit(MutationTypes.SET_CURRENT_PROJECT, projectResp.data);
    return projectResp.data;
  },
  [ActionTypes.FETCH_PACKAGE]: async ({ commit }, packageId) => {
    const packageResp = await axios.get(`${STORE_BASE_URL}/packages/${packageId}`);
    commit(MutationTypes.SET_CURRENT_PACKAGE, packageResp.data);
    return packageResp.data;
  },
  [ActionTypes.CREATE_PROJECT]: async ({ commit }, project) => {
    const projectResp = await axios.post(`${STORE_BASE_URL}/projects`, project);
    commit(MutationTypes.ADD_AND_SET_CURRENT_PROJECT, projectResp.data);
    return projectResp.data;
  },
  [ActionTypes.CREATE_PACKAGE]: async ({ commit, dispatch }, pkg) => {
    const packageResp = await axios.post(`${STORE_BASE_URL}/packages`, pkg);
    await dispatch(ActionTypes.FETCH_PROJECTS);
    commit(MutationTypes.SET_CURRENT_PACKAGE, null);
    return packageResp.data;
  },
  [ActionTypes.CREATE_PROJECT_TAG]: async ({ commit, state }, { projectId, packageId, tagName }) => {
    await axios.post(`${STORE_BASE_URL}/projects/${projectId}/tags`, { packageId, name: tagName });
    commit(MutationTypes.ADD_PROJECT_TAG, { projectId, packageId, tagName });
    return state.projects!.find((p) => p.id === projectId)!;
  },
  [ActionTypes.UPDATE_PROJECT]: async ({ commit }, project) => {
    const resp = await axios.put(`${STORE_BASE_URL}/projects/${project.id}`, { ...project, packages: undefined });
    const newProject = commit(MutationTypes.UPDATE_PROJECT, resp.data);
    return newProject;
  }
};
