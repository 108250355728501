
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    title: String,
    hasFooter: Boolean,
  },
  setup(props) {
    const title = computed(() => props.title);

    return {
      title,
    };
  },
});
