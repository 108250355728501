
import { defineComponent, computed } from "vue";
import { PackageData } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import PanelSection from "../panel-general/PanelSection.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import StylesheetEditor from "../../inputs/StylesheetEditor.vue";

export default defineComponent({
  props: {
    pkgData: Object,
  },
  components: {
    Panel,
    PanelAttribute,
    StylesheetEditor,
  },
  setup(props) {
    const pkgData = computed(() => props.pkgData as PackageData);
    return {
      add() {
        let bd = pkgData.value;
        bd.themes = bd.themes || [];
        const suffix = bd.themes.filter((s) => s.name.startsWith("key")).length;
        bd.themes.push({ name: `key${suffix}`, stylesheet: {} });
      },
      isUpDisabled(i: number) {
        return i === 0;
      },
      isDownDisabled(i: number) {
        return i === pkgData.value.themes.length - 1;
      },
      up(i: number) {
        const s = [...pkgData.value.themes];
        [s[i], s[i - 1]] = [s[i - 1], s[i]];
        pkgData.value.themes = s;
      },
      down(i: number) {
        const s = [...pkgData.value.themes];
        [s[i], s[i + 1]] = [s[i + 1], s[i]];
        pkgData.value.themes = s;
      },
      remove(i: number) {
        if (!confirm('Remove theme?')) return;
        const s = [...pkgData.value.themes];
        s.splice(i, 1);
        pkgData.value.themes = s;
      },
    };
  },
});
