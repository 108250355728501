import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-064482da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "monaco-wrapper",
  ref: "wrapperEl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonacoEditor = _resolveComponent("MonacoEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MonacoEditor, {
      class: "editor",
      language: _ctx.language,
      amdRequire: _ctx.monacoLoader(),
      value: _ctx.code,
      theme: "vs-dark",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.change($event))),
      onEditorDidMount: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mountedEditor($event)))
    }, null, 8, ["language", "amdRequire", "value"])
  ], 512))
}