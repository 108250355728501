import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { RootState } from "./state";
import { Project, Package } from "@bubblydoo/common";

export type Mutations<S = RootState> = {
  [MutationTypes.SET_PROJECTS](state: S, payload: Project[]): void;
  [MutationTypes.SET_CURRENT_PROJECT](state: S, payload: Project | null): void;
  [MutationTypes.SET_CURRENT_PACKAGE](state: S, payload: Package | null): void;
  [MutationTypes.ADD_AND_SET_CURRENT_PROJECT](state: S, payload: Project): void;
  [MutationTypes.ADD_AND_SET_CURRENT_PACKAGE](state: S, payload: Package): void;
  [MutationTypes.ADD_PROJECT_TAG](
    state: S,
    payload: { projectId: string; packageId: string; tagName: string }
  ): void;
  [MutationTypes.UPDATE_PROJECT](state: S, payload: Partial<Project>): Project;
};

export const mutations: MutationTree<RootState> & Mutations = {
  [MutationTypes.SET_PROJECTS](state, payload) {
    state.projects = payload;
  },
  [MutationTypes.SET_CURRENT_PROJECT](state, payload) {
    state.currentProject = payload;
  },
  [MutationTypes.SET_CURRENT_PACKAGE](state, payload) {
    state.currentPackage = payload;
  },
  [MutationTypes.ADD_AND_SET_CURRENT_PROJECT](state, payload) {
    let projects = state.projects || [];
    projects = [...projects, payload];
    state.projects = projects;
  },
  [MutationTypes.ADD_AND_SET_CURRENT_PACKAGE](state, payload) {
    const project = state.currentProject!;
    project.packages = [...project.packages!, payload];
    state.currentPackage = payload;
  },
  [MutationTypes.ADD_PROJECT_TAG](state, payload) {
    const projects = [...state.projects!];
    const projIdx = projects.findIndex((p) => p.id === payload.projectId);
    const proj = projects[projIdx];
    projects[projIdx] = { ...proj, tags: { ...proj.tags, [`${payload.tagName}`]: payload.packageId } };
    state.projects = projects;
  },
  [MutationTypes.UPDATE_PROJECT](state, payload) {
    const projects = [...state.projects!];
    const projIdx = projects.findIndex((p) => p.id === payload.id);
    const proj = projects[projIdx];
    projects[projIdx] = { ...proj, name: payload.name! };
    state.projects = projects;
    return projects[projIdx];
  }
};
