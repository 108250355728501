export const menuItems = [
  {
    name: "File",
    subItems: [
      {
        items: [
          {
            name: "New Spread",
          },
          {
            name: "New View",
          },
          {
            name: "New Project",
          },
        ]
      },
      {
        items: [
          {
            name: "Open",
          },
          {
            name: "Save",
          },
        ],
      },
      {
        items: [
          {
            name: "Preferences",
          }
        ],
      },
    ],
  },
  {
    name: "Edit",
    subItems: [
      {
        items: [
          {
            name: "Undo",
          },
          {
            name: "Redo",
          },
        ],
      },
      {
        items: [
          {
            name: "Cut",
          },
          {
            name: "Copy",
          },
          {
            name: "Paste",
          },
        ],
      },
    ],
  },
];
