
import { defineComponent, computed } from "vue";
import { PackageData } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import PanelSection from "../panel-general/PanelSection.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import MonacoWrapper from "../../MonacoWrapper.vue";

type Snippet = {
  name: string;
  value: string;
}

export default defineComponent({
  props: {
    pkgData: Object,
  },
  components: {
    Panel,
    PanelAttribute,
    MonacoWrapper,
  },
  setup(props) {
    const pkgData = computed(() => props.pkgData as PackageData);
    return {
      add() {
        let bd = pkgData.value;
        bd.snippets = bd.snippets || [];
        const suffix = bd.snippets.filter((s) => s.name.startsWith("key")).length;
        bd.snippets.push({ name: `key${suffix}`, value: "" });
      },
      async pasteFromClipboard() {
        let bd = pkgData.value;
        const clipboard = await navigator.clipboard.readText();
        try {
          let newSnippets: Snippet[] = JSON.parse(clipboard)
          bd.snippets = newSnippets;
        } catch (err) {
          alert(`${err} - clipboard: ${clipboard}`)
        }
      },
      async copyToClipboard() {
        let bd = pkgData.value;
        let { snippets } = bd;
        await navigator.clipboard.writeText(JSON.stringify(snippets))
        alert(`Copied Snippets to clipboard!`)
      },
      isUpDisabled(i: number) {
        return i === 0;
      },
      isDownDisabled(i: number) {
        return i === pkgData.value.snippets.length - 1;
      },
      up(i: number) {
        const s = [...pkgData.value.snippets];
        [s[i], s[i - 1]] = [s[i - 1], s[i]];
        pkgData.value.snippets = s;
      },
      down(i: number) {
        const s = [...pkgData.value.snippets];
        [s[i], s[i + 1]] = [s[i + 1], s[i]];
        pkgData.value.snippets = s;
      },
      remove(i: number) {
        if (!confirm('Remove snippet?')) return;
        const s = [...pkgData.value.snippets];
        s.splice(i, 1);
        pkgData.value.snippets = s;
      },
    };
  },
});
