
import { defineComponent, ref, watch, computed, onMounted, unref, ComputedRef } from "vue";
import { Package, Project, PackageData, PreviewArgument } from "@bubblydoo/common";
import MonacoWrapper, { monacoSettings } from "../MonacoWrapper.vue";
import ConfirmingButton from "./ConfirmingButton.vue";

import { PROCESSOR_WS_URL, TRANSFO_API_URL, STORE_BASE_URL } from "@/config";
import { index } from "mathjs";
import ExportRender from "./ExportRender.vue";

const DEFAULT_JSON_STRING = "{}";


export type Type = "image" | "pdf";
export type Quality = "10" | "50" | "80" | "100" | "200" | "300";
export type ImageType = "jpeg" | "png";

export type SpreadStatus =
    | "capturing"
    | "post-processing"
    | "complete"
    | "error"
    | "pending"
    | "resolver-done";

export type GenerationOptions = {
    type: Type;
    quality: Quality;
    imageType: ImageType;
    views: string[];
};

export type SpreadElement = {
    status: SpreadStatus;
    url: string;
};

export type ViewElement = {
    spreads: SpreadElement[];
    name: string;
    pdfUrl: string;
    completion: number;
};

export type QueueElement = {
    argumentName: string;
    packageId: string;
    generationOptions: GenerationOptions;
    transfoStoreUrl: string;
    transfoObject: any;
    status:
    | "pending"
    | "resolver-done"
    | "capturing"
    | "post-processing"
    | "complete"
    | "error";
    completionPercentage: number;
    views: ViewElement[];
};

export default defineComponent({
    props: {
        pkg: Object,
        previewArguments: Array,
        renderView: Array,
    },
    setup(props, { emit }) {
        const renderView = computed(() => props.renderView as string[]);
        const pkg = computed(() => props.pkg as Package);
        const baseQueueElements: ComputedRef<QueueElement[]> = computed(() => {
            console.log("ExportRenderContainer ~ previewArguments", props.previewArguments);
            const previewArgs = props.previewArguments as PreviewArgument[];
            return previewArgs.map(arg => {
                const queueElement: QueueElement = {
                    argumentName: arg.name,
                    packageId: pkg.value.id,
                    generationOptions: {
                        type: "pdf",
                        quality: "300",
                        imageType: "png",
                        views: renderView.value,
                    },
                    transfoStoreUrl: TRANSFO_API_URL,
                    transfoObject: arg.arguments,
                    status: "pending",
                    completionPercentage: 0,
                    views: renderView.value.map(view => {
                        return {
                            name: view,
                            spreads: [],
                            pdfUrl: "",
                            completion: 0,
                        };
                    })
                };
                return queueElement;
            });
        });
        console.log(renderView.value);
        console.log(pkg);
        console.log("baseQueueElements", baseQueueElements.value);
        const queueElements = ref<QueueElement[]>(baseQueueElements.value);
        const slots = ref<number>(3);
        const index = ref<number>(0);
        const alterQueue = async (index: number, message: any) => {
            const queue = queueElements.value;
            const newQueue = [...queue];
            const data = JSON.parse(message.data);
            const newElement = { ...queue[index] };
            const { type } = newElement.generationOptions;
            let pointer;
            if (data.pointer)
                pointer = data.pointer;
            if (data.results && data.results[0].pointer)
                pointer = data.results[0].pointer;
            const spreadStatuses = [0, 0, 0, 0];
            if (pointer) {
                const viewIndex = pointer[0];
                let spreads = [...newElement.views[viewIndex].spreads];
                const spreadCount = spreads.length;
                const spreadIndex = pointer[1];
                let viewCompletion = 0;
                let url = "";
                if (pointer[1] + 1 > spreadCount) {
                    spreads = [
                        ...spreads,
                        ...new Array(pointer[1] + 1 - spreadCount)
                            .fill(undefined)
                            .map(() => {
                                return {
                                    status: "pending" as const,
                                    url,
                                };
                            }),
                    ];
                }
                if (data.message === "capturer_done") {
                    spreads[spreadIndex].status =
                        type === "pdf" ? "post-processing" : "complete";
                    url = type === "pdf" ? "" : data.results[0].result.url;
                }
                else if (data.message === "postprocess_part_done") {
                    spreads[spreadIndex].status = "complete";
                    url = data.url;
                }
                else if (data.message === "capturer_part_done")
                    spreads[spreadIndex].status = "capturing";
                spreads[spreadIndex].url = url;
                newElement.views[viewIndex].spreads = spreads;
                spreadStatuses[2] = spreads.filter(spread => spread.status === "post-processing").length;
                spreadStatuses[1] = spreads.filter(spread => spread.status === "capturing").length;
                spreadStatuses[0] = spreads.filter(spread => spread.status === "pending").length;
                spreadStatuses[3] = spreads.filter(spread => spread.status === "complete").length;
                if (spreads.length > 0)
                    viewCompletion =
                        type === "pdf"
                            ? ((spreadStatuses[3] * 1 +
                                spreadStatuses[2] * 0.6666 +
                                spreadStatuses[1] * 0.3333) *
                                100) /
                            spreads.length
                            : ((spreadStatuses[3] * 1 + spreadStatuses[1] * 0.5) * 100) /
                            spreads.length;
                else
                    viewCompletion = 0;
                newElement.views[viewIndex].completion = viewCompletion;
            }
            const completionPercentage = newElement.views
                .map(v => v.completion)
                .reduce((previous: number, current: number, i, els) => previous + current);
            let status: SpreadStatus;
            newElement.completionPercentage = completionPercentage;
            if (spreadStatuses[2] > spreadStatuses[1] + spreadStatuses[0] &&
                completionPercentage > 0.6666)
                status = "post-processing";
            else if (spreadStatuses[1] > 0)
                status = "capturing";
            else if (type === "image" && spreadStatuses[3] + spreadStatuses[1] > 0)
                status = "capturing";
            else if (data.message === "consolidate_done" || data.message === "bye")
                status = "complete";
            else if (data.message === "resolve_done")
                status = "resolver-done";
            else if (data.message === "error") {
                status = "error";
                const errorMessage = JSON.parse(data.body.error.Cause);
                console.error(errorMessage);
                alert(`ERROR: ${errorMessage.errorMessage}`);
            }
            else
                status = "pending";
            newElement.status = status;
            if (data.message === "consolidate_done" && type === "pdf")
                Array.from(data.results).forEach((result: any, i: number) => (newElement.views[i].pdfUrl = result.url));
            newQueue[index] = newElement;
            console.warn(newQueue);
            queueElements.value = newQueue;
        };
        const generate = async (index: number) => {
            console.warn("generating", index);
            const queue = queueElements.value;
            const websocket = new WebSocket("wss://ws.processor.api.bubblydoo.net/prod");
            websocket.onopen = () => {
                slots.value--;
                const { packageId, generationOptions, transfoObject } = queue[index];
                websocket.send(JSON.stringify({
                    action: "process",
                    request: JSON.stringify({
                        packageId,
                        runArguments: transfoObject,
                        instruction: {
                            captures: generationOptions.views.map(v => {
                                return {
                                    view: v,
                                    spreads: "*",
                                };
                            }),
                            target: {
                                type: generationOptions.type,
                                options: {
                                    fileFormat: generationOptions.imageType,
                                },
                                consolidation: {
                                    level: generationOptions.type === "pdf" ? "view" : undefined,
                                },
                            },
                            notifications: {
                                websocket: {
                                    enabled: true,
                                    throwOnGone: true,
                                },
                            },
                            derivativeMaximumQuality: generationOptions.quality,
                            parallelization: "all",
                        },
                    }),
                }));
            };
            websocket.onmessage = async (message) => await alterQueue(index, message);
            websocket.onclose = () => {
                slots.value = (slots.value + 1 < 3) ? slots.value + 1 : 3;
            };
        };
        watch(slots, () => {
            console.log("slots", slots.value);
            if (slots.value > 0 && index.value < queueElements.value.length - 1) {
                const queue = queueElements.value;
                index.value++;
                generate(index.value);
            }
        });
        generate(0);
        return {
            slots,
            queueElements
        };
    },
    components: { ExportRender }
});
