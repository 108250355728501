
import { defineComponent, ref, reactive, computed, watch } from "vue";
import Menu from "./menu/Menu.vue";
import { useStore } from "../store/store";
import { MutationTypes } from "../store/mutation-types";
import { ActionTypes } from "../store/action-types";
import { Spread, Layer, PreviewArgument, Package } from "@bubblydoo/common";

export default defineComponent({
  props: [],
  components: {
    Menu,
  },
  setup(props, { emit }) {
    const store = useStore();
    const currentProject = computed(() => store.getters.currentProject);
    const currentPackage = computed(() => store.getters.currentPackage);

    return {
      currentProject,
      currentPackage,
    };
  },
});
