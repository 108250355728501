
import { defineComponent, ref, watch, computed, onMounted, unref } from "vue";
import MonacoWrapper, { monacoSettings } from "../MonacoWrapper.vue";
import isEqualDeep from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import type { editor as monacoEditor } from "monaco-editor";
import { copyFile } from "fs";
import ConfirmingButton from "./ConfirmingButton.vue";

const DEFAULT_JSON_STRING = "{}";

export default defineComponent({
  props: {
    modelValue: Object,
    schema: Object,
    copyable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MonacoWrapper,
    ConfirmingButton
  },
  setup(props, { emit }) {
    const jsonString = ref<string>(DEFAULT_JSON_STRING);
    let internalObject: any = {};

    const jsonStringToArguments = (str: string) => {
      return JSON.parse(str);
    };

    const checkJsonValidity = (str: string, alertOnError: boolean = false) => {
      try {
        const object = jsonStringToArguments(str);
        // internalObject will only be updated in conjunction with an emit
        if (isEqualDeep(object, internalObject)) return;
        // emit will also cause the v-model to provide another update
        // by setting internalObject, we can check if the stylesheet originated
        // from internally or externally
        internalObject = object;
        // console.log('[JsonEditor] emitting', object)
        emit("update:modelValue", cloneDeep(object));
      } catch (e) {
        // Invalid json
        if (alertOnError) alert(`Json cannot be read: ${e}`)
      }
    }

    watch(jsonString, (str) => {
      checkJsonValidity(str);
    });

    const objectToJsonString = (object: any) => {
      return JSON.stringify(object, null, 2);
    };

    const object = computed(() => props.modelValue || {});

    const syncObjToString = () => {
      jsonString.value = objectToJsonString(object.value || {});
    };

    watch(object, (object) => {
      if (isEqualDeep(internalObject, object)) return;
      // json changed externally
      console.log("[JsonEditor] object externally changed", object);
      syncObjToString();
      internalObject = cloneDeep(object || {});
    });

    onMounted(() => {
      syncObjToString();
      internalObject = cloneDeep(object.value || {});
    });

    const changedSchema = (
      schema: any,
      editor: monacoEditor.IStandaloneCodeEditor,
      monaco: monacoSettings
    ) => {
      if (!schema) return;

      const model = editor.getModel();

      if (!model) {
        console.warn("No json editor model");
        return;
      }

      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: model.uri.toString(),
            fileMatch: [model.uri.toString()],
            // Can't postMessage a Proxy
            // "DOMException: Failed to execute 'postMessage' on 'Worker': [object Object] could not be cloned."
            // need to clone
            schema: cloneDeep(schema),
          },
        ],
      });

      editor.setModel(model);
    };

    let monacoObj: { editor: monacoEditor.IStandaloneCodeEditor; monaco: monacoSettings };

    watch(
      () => props.schema,
      (schema) => {
        if (!monacoObj) {
          console.warn("Monaco not initialized yet");
          return;
        }
        changedSchema(schema, monacoObj.editor, monacoObj.monaco);
      }
    );

    const copy = async () => {
      await navigator.clipboard.writeText(JSON.stringify(internalObject))
    }
    const paste = async () => {
      const pasteAble = await navigator.clipboard.readText();
      checkJsonValidity(pasteAble, true)
    }

    return {
      jsonString,
      copy, paste,
      initializeEditor(obj: typeof monacoObj) {
        monacoObj = obj;
        changedSchema(props.schema, obj.editor, obj.monaco);
      },
    };
  },
});
