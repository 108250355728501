import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7cd393c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "menu-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "menu-item",
        key: item
      }, [
        _createElementVNode("button", {
          onClick: ($event: any) => (_ctx.toggleSelect(item)),
          class: _normalizeClass({ active: _ctx.isActive(item) })
        }, _toDisplayString(item.name), 11, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subItems, (subitemlist) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "menu-dropdown-part",
              key: subitemlist
            }, _toDisplayString(subitemlist[0]), 1))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}