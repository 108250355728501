
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: ["workspace"],
  components: {},
  setup(props) {
    const style = computed(() => {
      let keyValuePair = [
        ["grid-template-rows", props.workspace.rows],
        ["grid-template-columns", props.workspace.columns],
      ];
      if (props.workspace.styles) keyValuePair = keyValuePair.concat(props.workspace.styles);
      return Object.fromEntries(keyValuePair);
    });

    return {
      style,
      getStyle(space: any) {
        let keyValuePair = [
          ["grid-row", space.row],
          ["grid-column", space.column],
        ];
        if (space.styles) keyValuePair = keyValuePair.concat(space.styles);
        return Object.fromEntries(keyValuePair);
      },
    };
  },
});
