
import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "@/store/store";

export default defineComponent({
  props: {
    label: String,
  },
  components: {},
  setup(props, { emit }) {
    const clicked = ref<boolean>(false)
    const label = computed(() => props.label || "");
    const toggleConfirmation = () => {
      emit('update:callback')
      clicked.value = true;
      setTimeout(() => { clicked.value = false }, 2000);
    }
    return {
      label, clicked,
      toggleConfirmation
    };
  },
});
