import { GetterTree } from "vuex";
import { RootState } from "./state";
import { Project, Package } from "@bubblydoo/common";

export type Getters = {
  projects(state: RootState): Project[] | null;
  currentProject(state: RootState): Project | null;
  currentPackage(state: RootState): Package | null;
};

export const getters: GetterTree<RootState, RootState> & Getters = {
  projects: (state) => state.projects,
  currentProject: (state) => state.currentProject,
  currentPackage: (state) => state.currentPackage,
};
