export const TRANSFO_API_URL = process.env.VUE_APP_TRANSFO_API_BASE_URL || (() => {
  if (window.location.host.includes('backend-dev.')) {
    return "https://transfo-store-proxy-dev-backend.bubblydoo.net/dev";
  } else if (window.location.host.includes('backend.')) {
    return "https://transfo-store-proxy-prod-backend.bubblydoo.net/prod";
  } else {
    return "http://localhost:8081";
  }
})();

export const STORE_BASE_URL = process.env.VUE_APP_API_BASE_URL || (() => {
  if (window.location.host.includes('backend-dev.')) {
    return "https://books-store-next.backend.bubbly-doo.com/dev";
  } else if (window.location.host.includes('backend.')) {
    return "https://books-store-next.backend.bubbly-doo.com/prod";
  } else {
    return "http://localhost:8083";
  }
})();

export const RENDERER_FRONTEND_URL = process.env.VUE_APP_RENDERER_FRONTEND_URL || (() => {
  if (window.location.host.includes('backend-dev.')) {
    return "https://renderer-frontend.backend-dev.bubbly-doo.com/";
  } else if (window.location.host.includes('backend.')) {
    return "https://renderer-frontend.backend.bubbly-doo.com/";
  } else {
    return "http://localhost:8081";
  }
})();

export const PROCESSOR_WS_URL = process.env.VUE_APP_PROCESSOR_WS_URL || (() => {
  if (window.location.host.includes('backend.')) {
    return "wss://ws.processor.api.bubblydoo.net/prod";
  } else {
    return "wss://ws.processor.api.bubblydoo.net/dev";
  }
})();
