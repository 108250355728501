import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { addMonacoSupport } from "./monaco-support";

addMonacoSupport();

const app = createApp(App);
app.config.isCustomElement = (tag) => /^artboard-/.test(tag);

app.use(router).mount("#app");
