export function swapArrayElements(arr: any[], i: number, j: number): void {
  const t = arr[i];
  arr[i] = arr[j];
  arr[j] = t;
}

export function swapArrayElementsNew<T>(arr: T[], i: number, j: number): T[] {
  const arr2 = [...arr];
  swapArrayElements(arr2, i, j);
  return arr2;
}

export function removeFromArray<T>(arr: T[], i: number): T[] {
  const arr2 = [...arr];
  arr2.splice(i, 1);
  return arr2;
}

