
import { defineComponent, computed, ref, watch } from "vue";
import type { PackageData, PreviewArgument } from "@bubblydoo/common";
import Panel from "../panel-general/Panel.vue";
import PanelAttribute from "../panel-general/PanelAttribute.vue";
import JsonEditor from "../../inputs/JsonEditor.vue";
import { resolve } from "@bubblydoo/resolver";
import { RENDERER_FRONTEND_URL, TRANSFO_API_URL } from "@/config";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  props: {
    pkg: Object,
    pkgData: Object,
    pickedArgument: Object,
  },
  components: {
    Panel,
    PanelAttribute,
    JsonEditor,
  },
  setup(props, { emit }) {
    const pkgData = computed(() => props.pkgData as PackageData);

    const picked = ref<PreviewArgument | undefined>(props.pickedArgument as any);

    watch(
      () => props.pickedArgument,
      (pa) => {
        picked.value = pa as any;
      }
    );

    watch(picked, (p) => {
      console.log("[PreviewPanel] picked", p);
      emit("update:pickedArgument", p);
    });

    const visible = ref<boolean[]>([]);

    const automated = ref<boolean>(false);
    const renderFirst = ref<boolean>(true);
    const rendererFrontendUrl = ref<string>(RENDERER_FRONTEND_URL);

    return {
      picked,
      visible,
      automated,
      renderFirst,
      rendererFrontendUrl,
      add() {
        let bd = pkgData.value;
        bd.previewArguments = bd.previewArguments || [];
        bd.previewArguments.push({ name: "Arguments", arguments: {} });
      },
      remove(i: number) {
        if (!confirm("Remove preview argument?")) return;
        const s = [...pkgData.value.previewArguments];
        s.splice(i, 1);
        pkgData.value.previewArguments = s;
      },
      async openResolvedBook() {
        const runArguments = picked.value!.arguments;
        debugger;
        const result = await resolve(
          props.pkg! as any,
          {
            views: Object.keys(pkgData.value.views),
          },
          {
            transfo: {
              storeUrl: TRANSFO_API_URL,
              maximumQuality: pkgData.value.previewSettings.maximumQuality,
            },
          },
          true
        );
        const w = window.open(
          `${rendererFrontendUrl.value}?automated=${automated.value}`,
          "_blank"
        );
        if (!w) {
          debugger;
          return;
        }
        setTimeout(() => {
          // proxy doesn't work, must clone
          w.postMessage(
            cloneDeep({
              message: "resolvedPackage",
              data: {
                pkg: result,
                runArguments,
                // TODO: make render selectable
                render: renderFirst.value
                  ? {
                      view: Object.keys(pkgData.value.views)[0],
                      spread: 0,
                    }
                  : undefined,
              },
            }),
            rendererFrontendUrl.value
          );
        }, 2500);
      },
    };
  },
});
