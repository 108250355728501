
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
  watchEffect,
  triggerRef,
  isReactive,
} from "vue";
import DimensionInput from "./DimensionInput.vue";
import StylesheetEditor from "./StylesheetEditor.vue";
import { TextLineConfiguration } from "@bubblydoo/common";
import cloneDeep from "lodash/cloneDeep";
import isEqualDeep from "lodash/isEqual";
import { clone } from "lodash";

export default defineComponent({
  props: {
    modelValue: Object,
    text: String,
    index: Number,
    advancedMode: Boolean,
  },
  components: {
    DimensionInput,
    StylesheetEditor,
  },
  setup(props, { emit }) {
    const internalConfig = ref<TextLineConfiguration>(
      cloneDeep(props.modelValue as TextLineConfiguration)
    );
    const config = computed(() => props.modelValue as TextLineConfiguration);

    watch(config, (config) => {
      if (isEqualDeep(config, internalConfig.value)) return;
      internalConfig.value = cloneDeep(config);
    });

    const emitValues = (c: TextLineConfiguration) => {
      if (isEqualDeep(config.value, c)) return;
      console.log("[LineConfigurationsEditorLine] update");
      emit("update:modelValue", cloneDeep(c));
    };

    // doesn't work when watchEffect isn't there
    //
    // watch(internalConfig, (c) => {
    //   console.log('internal config changed');
    //   emitValues(c);
    // });

    watchEffect(() => {
      emitValues(internalConfig.value);
    });

    return {
      internalConfig,
      text: computed(() => props.text),
      index: computed(() => props.index),
      advancedMode: computed(() => !!props.advancedMode),
      cssProperties: computed(() => {
        const properties = new Set(Object.keys(internalConfig.value.stylesheet));
        properties.add("padding-left");
        return properties;
      }),
    };
  },
});
