
import { defineComponent, ref, watch, computed, onMounted, unref } from "vue";
import { Package, Project, PackageData, PreviewArgument } from "@bubblydoo/common";
import MonacoWrapper, { monacoSettings } from "../MonacoWrapper.vue";
import ConfirmingButton from "./ConfirmingButton.vue";
import { QueueElement } from "./ExportRenderContainer.vue";

const DEFAULT_JSON_STRING = "{}";

export default defineComponent({
  props: {
    queueElement: Object
  },
  setup(props, { emit }) {
    console.log("Does this run?")
    const queueElement = computed(() => props.queueElement as QueueElement);

    return {
      queueElement
    };
  },
});
