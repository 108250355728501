var commonjsGlobal = typeof globalThis !== 'undefined' ? globalThis : typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : typeof self !== 'undefined' ? self : {};

function getDefaultExportFromCjs (x) {
	return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, 'default') ? x['default'] : x;
}

var browserPonyfill = {exports: {}};

(function (module, exports) {
	var global = typeof self !== 'undefined' ? self : commonjsGlobal;
	var __self__ = (function () {
	function F() {
	this.fetch = false;
	this.DOMException = global.DOMException;
	}
	F.prototype = global;
	return new F();
	})();
	(function(self) {

	((function (exports) {

	  var support = {
	    searchParams: 'URLSearchParams' in self,
	    iterable: 'Symbol' in self && 'iterator' in Symbol,
	    blob:
	      'FileReader' in self &&
	      'Blob' in self &&
	      (function() {
	        try {
	          new Blob();
	          return true
	        } catch (e) {
	          return false
	        }
	      })(),
	    formData: 'FormData' in self,
	    arrayBuffer: 'ArrayBuffer' in self
	  };

	  function isDataView(obj) {
	    return obj && DataView.prototype.isPrototypeOf(obj)
	  }

	  if (support.arrayBuffer) {
	    var viewClasses = [
	      '[object Int8Array]',
	      '[object Uint8Array]',
	      '[object Uint8ClampedArray]',
	      '[object Int16Array]',
	      '[object Uint16Array]',
	      '[object Int32Array]',
	      '[object Uint32Array]',
	      '[object Float32Array]',
	      '[object Float64Array]'
	    ];

	    var isArrayBufferView =
	      ArrayBuffer.isView ||
	      function(obj) {
	        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
	      };
	  }

	  function normalizeName(name) {
	    if (typeof name !== 'string') {
	      name = String(name);
	    }
	    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
	      throw new TypeError('Invalid character in header field name')
	    }
	    return name.toLowerCase()
	  }

	  function normalizeValue(value) {
	    if (typeof value !== 'string') {
	      value = String(value);
	    }
	    return value
	  }

	  // Build a destructive iterator for the value list
	  function iteratorFor(items) {
	    var iterator = {
	      next: function() {
	        var value = items.shift();
	        return {done: value === undefined, value: value}
	      }
	    };

	    if (support.iterable) {
	      iterator[Symbol.iterator] = function() {
	        return iterator
	      };
	    }

	    return iterator
	  }

	  function Headers(headers) {
	    this.map = {};

	    if (headers instanceof Headers) {
	      headers.forEach(function(value, name) {
	        this.append(name, value);
	      }, this);
	    } else if (Array.isArray(headers)) {
	      headers.forEach(function(header) {
	        this.append(header[0], header[1]);
	      }, this);
	    } else if (headers) {
	      Object.getOwnPropertyNames(headers).forEach(function(name) {
	        this.append(name, headers[name]);
	      }, this);
	    }
	  }

	  Headers.prototype.append = function(name, value) {
	    name = normalizeName(name);
	    value = normalizeValue(value);
	    var oldValue = this.map[name];
	    this.map[name] = oldValue ? oldValue + ', ' + value : value;
	  };

	  Headers.prototype['delete'] = function(name) {
	    delete this.map[normalizeName(name)];
	  };

	  Headers.prototype.get = function(name) {
	    name = normalizeName(name);
	    return this.has(name) ? this.map[name] : null
	  };

	  Headers.prototype.has = function(name) {
	    return this.map.hasOwnProperty(normalizeName(name))
	  };

	  Headers.prototype.set = function(name, value) {
	    this.map[normalizeName(name)] = normalizeValue(value);
	  };

	  Headers.prototype.forEach = function(callback, thisArg) {
	    for (var name in this.map) {
	      if (this.map.hasOwnProperty(name)) {
	        callback.call(thisArg, this.map[name], name, this);
	      }
	    }
	  };

	  Headers.prototype.keys = function() {
	    var items = [];
	    this.forEach(function(value, name) {
	      items.push(name);
	    });
	    return iteratorFor(items)
	  };

	  Headers.prototype.values = function() {
	    var items = [];
	    this.forEach(function(value) {
	      items.push(value);
	    });
	    return iteratorFor(items)
	  };

	  Headers.prototype.entries = function() {
	    var items = [];
	    this.forEach(function(value, name) {
	      items.push([name, value]);
	    });
	    return iteratorFor(items)
	  };

	  if (support.iterable) {
	    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
	  }

	  function consumed(body) {
	    if (body.bodyUsed) {
	      return Promise.reject(new TypeError('Already read'))
	    }
	    body.bodyUsed = true;
	  }

	  function fileReaderReady(reader) {
	    return new Promise(function(resolve, reject) {
	      reader.onload = function() {
	        resolve(reader.result);
	      };
	      reader.onerror = function() {
	        reject(reader.error);
	      };
	    })
	  }

	  function readBlobAsArrayBuffer(blob) {
	    var reader = new FileReader();
	    var promise = fileReaderReady(reader);
	    reader.readAsArrayBuffer(blob);
	    return promise
	  }

	  function readBlobAsText(blob) {
	    var reader = new FileReader();
	    var promise = fileReaderReady(reader);
	    reader.readAsText(blob);
	    return promise
	  }

	  function readArrayBufferAsText(buf) {
	    var view = new Uint8Array(buf);
	    var chars = new Array(view.length);

	    for (var i = 0; i < view.length; i++) {
	      chars[i] = String.fromCharCode(view[i]);
	    }
	    return chars.join('')
	  }

	  function bufferClone(buf) {
	    if (buf.slice) {
	      return buf.slice(0)
	    } else {
	      var view = new Uint8Array(buf.byteLength);
	      view.set(new Uint8Array(buf));
	      return view.buffer
	    }
	  }

	  function Body() {
	    this.bodyUsed = false;

	    this._initBody = function(body) {
	      this._bodyInit = body;
	      if (!body) {
	        this._bodyText = '';
	      } else if (typeof body === 'string') {
	        this._bodyText = body;
	      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
	        this._bodyBlob = body;
	      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
	        this._bodyFormData = body;
	      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
	        this._bodyText = body.toString();
	      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
	        this._bodyArrayBuffer = bufferClone(body.buffer);
	        // IE 10-11 can't handle a DataView body.
	        this._bodyInit = new Blob([this._bodyArrayBuffer]);
	      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
	        this._bodyArrayBuffer = bufferClone(body);
	      } else {
	        this._bodyText = body = Object.prototype.toString.call(body);
	      }

	      if (!this.headers.get('content-type')) {
	        if (typeof body === 'string') {
	          this.headers.set('content-type', 'text/plain;charset=UTF-8');
	        } else if (this._bodyBlob && this._bodyBlob.type) {
	          this.headers.set('content-type', this._bodyBlob.type);
	        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
	          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
	        }
	      }
	    };

	    if (support.blob) {
	      this.blob = function() {
	        var rejected = consumed(this);
	        if (rejected) {
	          return rejected
	        }

	        if (this._bodyBlob) {
	          return Promise.resolve(this._bodyBlob)
	        } else if (this._bodyArrayBuffer) {
	          return Promise.resolve(new Blob([this._bodyArrayBuffer]))
	        } else if (this._bodyFormData) {
	          throw new Error('could not read FormData body as blob')
	        } else {
	          return Promise.resolve(new Blob([this._bodyText]))
	        }
	      };

	      this.arrayBuffer = function() {
	        if (this._bodyArrayBuffer) {
	          return consumed(this) || Promise.resolve(this._bodyArrayBuffer)
	        } else {
	          return this.blob().then(readBlobAsArrayBuffer)
	        }
	      };
	    }

	    this.text = function() {
	      var rejected = consumed(this);
	      if (rejected) {
	        return rejected
	      }

	      if (this._bodyBlob) {
	        return readBlobAsText(this._bodyBlob)
	      } else if (this._bodyArrayBuffer) {
	        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer))
	      } else if (this._bodyFormData) {
	        throw new Error('could not read FormData body as text')
	      } else {
	        return Promise.resolve(this._bodyText)
	      }
	    };

	    if (support.formData) {
	      this.formData = function() {
	        return this.text().then(decode)
	      };
	    }

	    this.json = function() {
	      return this.text().then(JSON.parse)
	    };

	    return this
	  }

	  // HTTP methods whose capitalization should be normalized
	  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

	  function normalizeMethod(method) {
	    var upcased = method.toUpperCase();
	    return methods.indexOf(upcased) > -1 ? upcased : method
	  }

	  function Request(input, options) {
	    options = options || {};
	    var body = options.body;

	    if (input instanceof Request) {
	      if (input.bodyUsed) {
	        throw new TypeError('Already read')
	      }
	      this.url = input.url;
	      this.credentials = input.credentials;
	      if (!options.headers) {
	        this.headers = new Headers(input.headers);
	      }
	      this.method = input.method;
	      this.mode = input.mode;
	      this.signal = input.signal;
	      if (!body && input._bodyInit != null) {
	        body = input._bodyInit;
	        input.bodyUsed = true;
	      }
	    } else {
	      this.url = String(input);
	    }

	    this.credentials = options.credentials || this.credentials || 'same-origin';
	    if (options.headers || !this.headers) {
	      this.headers = new Headers(options.headers);
	    }
	    this.method = normalizeMethod(options.method || this.method || 'GET');
	    this.mode = options.mode || this.mode || null;
	    this.signal = options.signal || this.signal;
	    this.referrer = null;

	    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
	      throw new TypeError('Body not allowed for GET or HEAD requests')
	    }
	    this._initBody(body);
	  }

	  Request.prototype.clone = function() {
	    return new Request(this, {body: this._bodyInit})
	  };

	  function decode(body) {
	    var form = new FormData();
	    body
	      .trim()
	      .split('&')
	      .forEach(function(bytes) {
	        if (bytes) {
	          var split = bytes.split('=');
	          var name = split.shift().replace(/\+/g, ' ');
	          var value = split.join('=').replace(/\+/g, ' ');
	          form.append(decodeURIComponent(name), decodeURIComponent(value));
	        }
	      });
	    return form
	  }

	  function parseHeaders(rawHeaders) {
	    var headers = new Headers();
	    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	    // https://tools.ietf.org/html/rfc7230#section-3.2
	    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
	    preProcessedHeaders.split(/\r?\n/).forEach(function(line) {
	      var parts = line.split(':');
	      var key = parts.shift().trim();
	      if (key) {
	        var value = parts.join(':').trim();
	        headers.append(key, value);
	      }
	    });
	    return headers
	  }

	  Body.call(Request.prototype);

	  function Response(bodyInit, options) {
	    if (!options) {
	      options = {};
	    }

	    this.type = 'default';
	    this.status = options.status === undefined ? 200 : options.status;
	    this.ok = this.status >= 200 && this.status < 300;
	    this.statusText = 'statusText' in options ? options.statusText : 'OK';
	    this.headers = new Headers(options.headers);
	    this.url = options.url || '';
	    this._initBody(bodyInit);
	  }

	  Body.call(Response.prototype);

	  Response.prototype.clone = function() {
	    return new Response(this._bodyInit, {
	      status: this.status,
	      statusText: this.statusText,
	      headers: new Headers(this.headers),
	      url: this.url
	    })
	  };

	  Response.error = function() {
	    var response = new Response(null, {status: 0, statusText: ''});
	    response.type = 'error';
	    return response
	  };

	  var redirectStatuses = [301, 302, 303, 307, 308];

	  Response.redirect = function(url, status) {
	    if (redirectStatuses.indexOf(status) === -1) {
	      throw new RangeError('Invalid status code')
	    }

	    return new Response(null, {status: status, headers: {location: url}})
	  };

	  exports.DOMException = self.DOMException;
	  try {
	    new exports.DOMException();
	  } catch (err) {
	    exports.DOMException = function(message, name) {
	      this.message = message;
	      this.name = name;
	      var error = Error(message);
	      this.stack = error.stack;
	    };
	    exports.DOMException.prototype = Object.create(Error.prototype);
	    exports.DOMException.prototype.constructor = exports.DOMException;
	  }

	  function fetch(input, init) {
	    return new Promise(function(resolve, reject) {
	      var request = new Request(input, init);

	      if (request.signal && request.signal.aborted) {
	        return reject(new exports.DOMException('Aborted', 'AbortError'))
	      }

	      var xhr = new XMLHttpRequest();

	      function abortXhr() {
	        xhr.abort();
	      }

	      xhr.onload = function() {
	        var options = {
	          status: xhr.status,
	          statusText: xhr.statusText,
	          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
	        };
	        options.url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
	        var body = 'response' in xhr ? xhr.response : xhr.responseText;
	        resolve(new Response(body, options));
	      };

	      xhr.onerror = function() {
	        reject(new TypeError('Network request failed'));
	      };

	      xhr.ontimeout = function() {
	        reject(new TypeError('Network request failed'));
	      };

	      xhr.onabort = function() {
	        reject(new exports.DOMException('Aborted', 'AbortError'));
	      };

	      xhr.open(request.method, request.url, true);

	      if (request.credentials === 'include') {
	        xhr.withCredentials = true;
	      } else if (request.credentials === 'omit') {
	        xhr.withCredentials = false;
	      }

	      if ('responseType' in xhr && support.blob) {
	        xhr.responseType = 'blob';
	      }

	      request.headers.forEach(function(value, name) {
	        xhr.setRequestHeader(name, value);
	      });

	      if (request.signal) {
	        request.signal.addEventListener('abort', abortXhr);

	        xhr.onreadystatechange = function() {
	          // DONE (success or failure)
	          if (xhr.readyState === 4) {
	            request.signal.removeEventListener('abort', abortXhr);
	          }
	        };
	      }

	      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
	    })
	  }

	  fetch.polyfill = true;

	  if (!self.fetch) {
	    self.fetch = fetch;
	    self.Headers = Headers;
	    self.Request = Request;
	    self.Response = Response;
	  }

	  exports.Headers = Headers;
	  exports.Request = Request;
	  exports.Response = Response;
	  exports.fetch = fetch;

	  Object.defineProperty(exports, '__esModule', { value: true });

	  return exports;

	}))({});
	})(__self__);
	__self__.fetch.ponyfill = true;
	// Remove "polyfill" property added by whatwg-fetch
	delete __self__.fetch.polyfill;
	// Choose between native implementation (global) or custom implementation (__self__)
	// var ctx = global.fetch ? global : __self__;
	var ctx = __self__; // this line disable service worker support temporarily
	exports = ctx.fetch; // To enable: import fetch from 'cross-fetch'
	exports.default = ctx.fetch; // For TypeScript consumers without esModuleInterop.
	exports.fetch = ctx.fetch; // To enable: import {fetch} from 'cross-fetch'
	exports.Headers = ctx.Headers;
	exports.Request = ctx.Request;
	exports.Response = ctx.Response;
	module.exports = exports;
} (browserPonyfill, browserPonyfill.exports));

var fetch = /*@__PURE__*/getDefaultExportFromCjs(browserPonyfill.exports);

class ResolveError extends Error {
    constructor(message, type, cause) {
        super(cause ? `${message}\ncause: ${cause.message}` : message);
        this.type = type;
        this.cause = cause;
        this.name = "ResolveError";
    }
}

class TransfoStoreClient {
    constructor(apiUrl) {
        this.cache = new Map();
        this.apiUrl = apiUrl;
    }
    async searchBatch(cmds) {
        const cmdHashkeysMap = new Map(cmds.map((c) => [c, this.createHashKey(c)]));
        const uniqueCmds = uniqueArrayByProperty(cmds, (c) => cmdHashkeysMap.get(c));
        let response;
        try {
            response = await fetch(`${this.apiUrl}/packages/search-batch`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(uniqueCmds),
            });
        }
        catch (e) {
            throw new ResolveError(`Network error while batch searching`, 2 /* ResolveErrorType.TransfoClientNetworkError */);
        }
        if (response.status !== 200) {
            const data = await response.json();
            if (response.status === 404) {
                throw new ResolveError(`Couldn't find package ${data.search.namespace}@${data.search.version} - ${data.search.derivative} while batch searching`, 1 /* ResolveErrorType.TransfoClientPackageNotFoundError */);
            }
            else {
                console.error(data);
                throw new ResolveError(`Unknown error while batch searching`, 3 /* ResolveErrorType.TransfoClientUnknownError */);
            }
        }
        const uniqueResults = (await response.json());
        // this maps points the cmds with original index to the index the have in the req & res
        // e.g. 0 -> 0, 1 -> 1, 2 -> 1, 3 -> 1, 4 -> 2, 5 -> 2, ...
        const cmdsIndexToUniqueCmdsIndexMap = cmds.map((c1) => uniqueCmds.findIndex((c2) => cmdHashkeysMap.get(c1) === cmdHashkeysMap.get(c2)));
        const results = cmds.map((_, i) => uniqueResults[cmdsIndexToUniqueCmdsIndexMap[i]]);
        results.forEach((r, i) => {
            const c = cmds[i];
            const hashKey = this.createHashKey(c);
            this.cache.set(hashKey, Promise.resolve(r));
        });
        return results;
    }
    async search({ namespace, version, derivative }) {
        const hashKey = this.createHashKey({ namespace, version, derivative });
        if (this.cache.has(hashKey))
            return await this.cache.get(hashKey);
        const promise = (async () => {
            try {
                const response = await fetch(`${this.apiUrl}/packages/search`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: JSON.stringify({
                        namespace,
                        version,
                        derivative,
                    }),
                });
                if (response.status !== 200) {
                    const data = await response.json();
                    if (response.status === 404) {
                        throw new ResolveError(`Couldn't find package ${namespace}@${version} - ${derivative}`, 1 /* ResolveErrorType.TransfoClientPackageNotFoundError */);
                    }
                    else {
                        console.error(data);
                        throw new ResolveError(`Unknown error while searching ${namespace}@${version} - ${derivative}`, 3 /* ResolveErrorType.TransfoClientUnknownError */);
                    }
                }
                const pkg = await response.json();
                this.cache.set(hashKey, Promise.resolve(pkg));
                return pkg;
            }
            catch (e) {
                this.cache.delete(hashKey);
                throw new ResolveError(`Network error while searching ${namespace}@${version} - ${derivative}`, 2 /* ResolveErrorType.TransfoClientNetworkError */);
            }
        })();
        this.cache.set(hashKey, promise);
        return promise;
    }
    createHashKey(c) {
        return `${c.namespace}-${c.version}-${c.derivative || "main"}`;
    }
}
function uniqueArrayByProperty(array, fn) {
    return [...new Map(array.map((item, i) => [fn(item, i), item])).values()];
}

// This client uses the search-batch endpoint of Transfo store.
// Traversing the layer tree to extract transfo layers, putting them in an array,
// searching and then putting them back in the tree is not easy.
// This client collects all searches and saves their resolve function.
// After all searches are put in the tree, the parent has to call "finish()"
// This method batch searches all registered search commands, and then resolves
// all promises.
// This also means that await-ing the search command will not work without
// calling "finish()" first.
// As such, the parent has to initiate all resolveLayer()-s, then call finish,
// and then await the Promise.all(). (see resolver.ts)
//
class BatchTransfoStoreClient {
    constructor(client) {
        this.client = client;
        this.accepting = true;
        this.finished = false;
        this.searches = [];
    }
    search(command) {
        if (!this.accepting)
            throw new ResolveError("Batch client already finished/finishing", 4 /* ResolveErrorType.TransfoBatchClientNotAcceptingError */);
        return new Promise((resolve) => {
            this.searches.push({
                command,
                resolve,
            });
        });
    }
    async finish() {
        if (!this.accepting)
            throw new ResolveError("Batch client already finished/finishing", 4 /* ResolveErrorType.TransfoBatchClientNotAcceptingError */);
        this.accepting = false;
        const results = await this.client.searchBatch(this.searches.map((s) => s.command));
        if (!results)
            return false;
        for (const iStr in this.searches) {
            const i = +iStr;
            this.searches[i].resolve(results[i]);
        }
        this.finished = true;
    }
}

const capDerivative = (d, maxQ) => {
    if (!maxQ)
        return d;
    if (!d)
        return `rasterized-png-${maxQ}`;
    if (d === 'main')
        return 'main';
    const s = d.split("-");
    const isLegacyRasterizedPng = d && d.startsWith("rasterized-png-");
    let layerDerivativeQuality;
    if (isLegacyRasterizedPng) {
        // e.g. rasterized-png-200
        layerDerivativeQuality = +s[s.length - 1];
    }
    else {
        // e.g. avif-100, avif-050-lossy-020, png-080
        layerDerivativeQuality = +s[1];
    }
    const q = Math.min(+maxQ, layerDerivativeQuality);
    const qStr = q.toString().padStart(3, "0");
    const derivative = isLegacyRasterizedPng
        ? `rasterized-png-${qStr}`
        : [s[0], qStr, ...s.slice(2)].join("-");
    return derivative;
};

// const conditionFilter = (part: { condition?: Condition }, runArguments: any) => {
//   const parser = new expr.Parser();
//   if (!part.condition || !part.condition.expression) return true;
//   return parser.evaluate(part.condition.expression, { r: runArguments });
// };
function basicLayerAttributes(l) {
    return {
        type: l.type,
        name: l.name,
        styling: l.styling,
        condition: l.condition,
        contextTransformations: l.contextTransformations,
    };
}
const resolveLayer = async (layer, opts, externalSpreadRegistry = null) => {
    if (layer.type === "external-spread") {
        if (!(externalSpreadRegistry === null || externalSpreadRegistry === void 0 ? void 0 : externalSpreadRegistry.has(layer.ref))) {
            throw new ResolveError(`External spread ref ${layer.ref} not found`, 0 /* ResolveErrorType.ExternalSpreadNotFoundError */);
        }
        const extSpread = externalSpreadRegistry.get(layer.ref);
        const extResolvedSpread = await resolveSpread(extSpread, opts, externalSpreadRegistry);
        const resolvedLayer = {
            ...basicLayerAttributes(layer),
            format: extResolvedSpread.format,
            layers: extResolvedSpread.layers,
            spreadName: extResolvedSpread.name,
        };
        return resolvedLayer;
    }
    else if (layer.type === "group") {
        const resolvedSubLayers = await Promise.all(layer.layers.map((l) => {
            return resolveLayer(l, opts, externalSpreadRegistry);
        }));
        const resolvedLayer = {
            ...basicLayerAttributes(layer),
            layers: resolvedSubLayers,
        };
        return resolvedLayer;
    }
    else if (layer.type === "transfo-svg") {
        let { namespace, version, derivative } = layer;
        derivative = capDerivative(derivative, opts.transfo.maximumQuality);
        // const pkg = await opts.transfoClient.search({ namespace, version, derivative });
        // const command = packageToPartialCommand(pkg, derivative);
        const resolvedLayer = {
            ...basicLayerAttributes(layer),
            type: "deferred-transfo-svg",
            styling: layer.styling,
            namespace,
            version,
            derivative,
            transfoStoreUrl: opts.transfo.storeUrl
        };
        return resolvedLayer;
    }
    else {
        return layer;
    }
};
const resolveSpread = async (spread, opts, externalSpreadRegistry = null, useBatchClient = false) => {
    const partsOpts = {
        ...opts,
        transfoClient: opts.transfoClient || getStoreClient(opts.transfo.storeUrl, useBatchClient),
    };
    const resolvedLayersPromise = Promise.all(spread.layers.map((l) => resolveLayer(l, partsOpts, externalSpreadRegistry)));
    if (useBatchClient && "finish" in partsOpts.transfoClient)
        await partsOpts.transfoClient.finish();
    const resolvedLayers = await resolvedLayersPromise;
    const resolvedSpread = {
        name: spread.name,
        format: spread.format,
        layers: resolvedLayers,
        croppings: spread.croppings,
        condition: spread.condition,
    };
    return resolvedSpread;
};
const resolveView = async (view, opts, externalSpreadRegistry = null, useBatchClient = false) => {
    const partsOpts = {
        ...opts,
        transfoClient: opts.transfoClient || getStoreClient(opts.transfo.storeUrl, useBatchClient),
    };
    const resolvedSpreadsPromise = Promise.all(view.spreads.map((l) => resolveSpread(l, partsOpts, externalSpreadRegistry)));
    if (useBatchClient && "finish" in partsOpts.transfoClient)
        await partsOpts.transfoClient.finish();
    const resolvedSpreads = await resolvedSpreadsPromise;
    const resolvedView = {
        spreads: resolvedSpreads,
    };
    return resolvedView;
};
const buildExternalSpreadRegistry = (views) => {
    const externalSpreadRegistry = new Map();
    Object.entries(views).forEach(([viewName, view]) => view.spreads.forEach((s, i) => externalSpreadRegistry.set(`${viewName}#${i}`, s)));
    return externalSpreadRegistry;
};
const resolve = async (pkg, instruction, opts, useBatchClient = false) => {
    if (!pkg)
        throw new Error("Package does not exist");
    const data = pkg.data;
    if (!pkg.data)
        throw new Error("Package data does not exist");
    const instructedViewNames = instruction.views;
    if (!instruction)
        throw new Error("Instruction does not exist");
    const instructedViews = instructedViewNames.map((viewName) => data.views[viewName]);
    // const instructedView = data.views[instructedViewName];
    // const spreads = instructedView.spreads;
    let externalSpreadRegistry = null;
    if (!opts.disallowExternalSpreads) {
        externalSpreadRegistry = buildExternalSpreadRegistry(data.views);
    }
    const partsOpts = {
        ...opts,
        transfoClient: opts.transfoClient || getStoreClient(opts.transfo.storeUrl, useBatchClient),
    };
    const resolvedViewsPromise = Promise.all(instructedViews.map(async (view) => {
        const resolvedView = await resolveView(view, partsOpts, externalSpreadRegistry);
        return resolvedView;
    }));
    if (useBatchClient && "finish" in partsOpts.transfoClient)
        await partsOpts.transfoClient.finish();
    const resolvedViewsArr = await resolvedViewsPromise;
    const resolvedViews = {};
    instructedViewNames.forEach((n, i) => (resolvedViews[n] = resolvedViewsArr[i]));
    const resolvedData = {
        snippets: data.snippets,
        themes: data.themes,
        fonts: data.fonts,
        views: resolvedViews,
    };
    const resolvedPkg = {
        id: pkg.id,
        version: pkg.version,
        data: resolvedData,
    };
    return resolvedPkg;
};
function getStoreClient(storeUrl, useBatchClient = false) {
    const baseClient = new TransfoStoreClient(storeUrl);
    return useBatchClient ? new BatchTransfoStoreClient(baseClient) : baseClient;
}

export { ResolveError, TransfoStoreClient, buildExternalSpreadRegistry, resolve, resolveSpread };
