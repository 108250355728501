import { ResultMapping } from "@bubblydoo/renderer";
import { Matrix } from "mathjs";

interface RenderService {
  resultMappings: ResultMapping[] | null;
  matrix: Matrix | null;
}

export const renderService: RenderService = {
  resultMappings: null,
  matrix: null
}
