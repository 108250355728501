
import { defineComponent, ref, reactive, computed, watch, onMounted, watchEffect } from "vue";
import LineConfigurationsEditorLine from "./LineConfigurationsEditorLine.vue";
import { TextLineConfiguration } from "@bubblydoo/common";
import cloneDeep from "lodash/cloneDeep";
import isEqualDeep from "lodash/isEqual";

export default defineComponent({
  props: {
    modelValue: Array,
    text: String,
    advancedMode: Boolean,
  },
  components: {
    Line: LineConfigurationsEditorLine,
  },
  setup(props, { emit }) {
    const count = ref(0);

    const lcs = computed(() => props.modelValue as TextLineConfiguration[]);
    const internalLcs = ref<TextLineConfiguration[]>(cloneDeep(lcs.value) || []);

    const splitText = computed(() => {
      return props.text ? props.text.split(/\r?\n/g) : [];
    });

    const lineCountFromText = computed(() => {
      const arr = splitText.value;
      return arr ? arr.length : null;
    });

    const lineConfigurationsCount = ref(lineCountFromText.value || 1);

    [lineConfigurationsCount, lineCountFromText].forEach((ref) =>
      watch(ref, () => {
        lineConfigurationsCount.value = Math.max(
          lineCountFromText.value!,
          lineConfigurationsCount.value
        );
        ensureLineConfigurations();
      })
    );

    // This will override cssString the whole time, so use the sync button
    // This is because css1 -> json -> css2 doesn't mean css1 = css2,
    // as they could have different whitespace, etc.
    //
    // watch(stylesheet, () => {
    //   syncSelectionToCss();
    // });

    const ensureLineConfigurations = () => {
      const count = lineConfigurationsCount.value;
      const len = internalLcs.value ? internalLcs.value.length : 0;
      if (len < count) {
        internalLcs.value = [
          ...(internalLcs.value || []),
          ...Array.from({ length: count - len }, (v, i) => ({ stylesheet: {} })),
        ];
      }
    };

    watch(lcs, (lcs) => {
      if (isEqualDeep(lcs, internalLcs.value)) return;
      internalLcs.value = cloneDeep(lcs as TextLineConfiguration[]);
    });

    ensureLineConfigurations();

    // watch(internalLcs, (value) => {
    //   console.log('[LineConfigurationsEditor] update or not');
    //   if (isEqualDeep(value, lcs.value)) return;
    //   emit("update:modelValue", value);
    // });

    watchEffect(() => {
      if (!isEqualDeep(internalLcs.value, lcs.value)) {
        emit("update:modelValue", cloneDeep(internalLcs.value));
      }
    });

    return {
      splitText,
      lineConfigurationsCount,
      internalLcs,
      advancedMode: computed(() => !!props.advancedMode),
    };
  },
});
